import React, { useState, useEffect, useCallback } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import Banner from './../Images/banner1.webp';
import Banner2 from './../Images/banner2.avif';
import supabaseClient from '../../supabaseClient';

const Sellerpage = () => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const checkGoogleID = useCallback(async (GID) => {

    try {
      const { data, error } = await supabaseClient
        .from('Users')
        .select('*')
        .eq('GoogleID', String(GID.id));

      if (error) {
        console.error('Error checking Google ID:', error.message);
      } else {

        if (data.length > 0) {
          setUser(data);
          alert('To join as Seller login with new mail-address')
        } else {
          localStorage.setItem('profile', JSON.stringify(GID));
          navigate('/ProfileDetails');
          window.location.reload(true);
        }
      }
    } catch (error) {
      console.error('Error checking Google ID:', error.message);
    }
  }, []);
  const navigate = useNavigate();

  const onSuccessLogin = async (codeResponse) => {
    setUser({ ...codeResponse, tokenCreatedAt: new Date() });
    localStorage.setItem('user', JSON.stringify({ ...codeResponse, tokenCreatedAt: new Date() }));
    fetchUserProfile({ ...codeResponse, tokenCreatedAt: new Date() }.access_token)
    // navigate('/ProfileDetails');
  };

  const login = useGoogleLogin({
    onSuccess: onSuccessLogin,
    onError: (error) => console.log('Login Failed:', error),
    scope: 'https://www.googleapis.com/auth/gmail.readonly',
  });

  const fetchUserProfile = (accessToken) => {
    fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        checkGoogleID(data);

      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className='lg:px-8 px-4 flex items-center flex-col'>
        <img src={Banner} className="rounded-lg my-4 w-full lg:max-w-7xl banner1css" alt="Banner 1" />
        <img src={Banner2} className="rounded-lg my-4 w-full lg:max-w-7xl banner2css" alt="Banner 2" />
      </div>
      <div className='flex justify-center pr-10 p-4'>
        <button className="transition-transform transform hover:scale-110 lg:text-black sm:text-black p-2 lg:px-4 lg:py-2 border bg-yellow-300 rounded-lg" onClick={login}>
          Join as Seller
        </button>
      </div>
    </>
  );
};

export default Sellerpage;
