import React, { useEffect, useState } from 'react';
import supabaseClient from '../../supabaseClient';
import { MdOutlineFirstPage,MdOutlineLastPage } from "react-icons/md";

const Raiseticket = ({ User }) => {
    const [orderID, setOrderID] = useState('');
    const [newTicketView, setnewTicketView] = useState(false);
    const [problem, setProblem] = useState('');
    const [sellerMail, setSellerMailm] = useState('');
    const [status, setStatus] = useState('Open');
    const [selecteditems, setSelecteditems] = useState([]);
    const [Tickets, setTickets] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [limit, setLimit] = useState(0);
    const user = User[0];

    const fetchSelectedItems = async () => {
        try {
            const { data, error } = await supabaseClient
                .from("RequestedItems")
                .select("*")
                .eq('UserMailID', user.MailID)
                .range(limit, limit + 9);

            if (error) {
                console.error('Error fetching requested items:', error.message);
            } else {
                setSelecteditems(data || []);
            }
        } catch (error) {
            console.error('Error fetching requested items:', error.message);
        }
    };
    const fetchTickets = async () => {
        try {
            const { data, error } = await supabaseClient
                .from("Tickets")
                .select("*")
                .eq('Buyer', user.MailID);

            if (error) {
                console.error('Error fetching requested items:', error.message);
            } else {
                setTickets(data || []);
            }
        } catch (error) {
            console.error('Error fetching requested items:', error.message);
        }
    };
    const updateStatus = async (itemId, newStatus) => {
        try {
            // Update the "Status" column in the "Tickets" table for the item with the given itemId
            const { data, error } = await supabaseClient
                .from('Tickets')
                .update({ Status: newStatus })
                .eq('id', itemId);

            if (error) {
                throw error;
            }
            fetchTickets();
            return data; // Return the updated data
        } catch (error) {
            console.error('Error updating status:', error.message);
            return null;
        }
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Validate the length of the problem description
        if (problem.length < 50) {
            setErrorMessage('Describe the issue in at least 50 characters.');
            return;
        }

        // Clear any previous error message
        setErrorMessage('');

        // Post values to the "Tickets" table
        const { data, error } = await supabaseClient.from('Tickets').upsert([
            {
                OrderID: orderID,
                Buyer: user.MailID,
                Problem: problem,
                Status: status,
                Seller: sellerMail,
            },
        ]);

        if (error) {
            console.error('Error raising ticket:', error.message);
            return;
        }
        // Reset form fields after submission
        setOrderID('');
        setProblem('');
        setStatus('Open');
        setnewTicketView(false);
        fetchTickets();
    };
    const PrvItem = () => {
        if (limit >= 10) {
            setLimit(limit - 10)
        }
    }
    const nextItem = () => {
        if (selecteditems.length >= 10) {
            setLimit(limit + 10)
        }
    }
    useEffect(() => {
        fetchSelectedItems();
        fetchTickets();
    }, [User, limit]);
    useEffect(() => {
        const sellerMails = selecteditems.find(item => item.id == orderID)?.SellerMail;
        setSellerMailm(sellerMails);
    }, [orderID]);

    return (
        <>
            <div class="flex justify-between">
                <button className="bg-blue-600 text-white px-4 py-2 rounded-md ml-2" onClick={() => setnewTicketView(true)}>
                    Raise New Ticket
                </button>
                <div className='lg:flex lg:justify-end md:flex md:justify-end sm:flex sm:justify-end flex justify-end'>
        <button class={`bg-blue-500 text-2xl py-2 px-4 rounded-lg shadow-md mr-2 ${limit == 0 ? 'hidden' : null}`} onClick={PrvItem} >
          <span class=""><MdOutlineFirstPage /></span>
        </button>
        <button class={`bg-blue-500 text-2xl py-2 px-4 rounded-lg shadow-md ${selecteditems.length < 10 ? 'hidden' : null}`} onClick={nextItem}>
          <span class=""><MdOutlineLastPage /></span>
        </button>
      </div>
            </div>
            <div className="max-w-md mx-auto p-8 bg-[#6cb1cf7d] rounded shadow-md text-blue-950" style={{ display: newTicketView ? '' : 'none' }}>
                <h2 className="text-2xl font-bold mb-6">Raise New Ticket</h2>

                <form onSubmit={handleFormSubmit} className="space-y-4">
                    <div className="flex flex-col">
                        <label htmlFor="orderID" className="text-sm font-semibold mb-1 text-blue-950">OrderID:</label>
                        <select
                            id="orderID"
                            value={orderID}
                            onChange={(e) => setOrderID(e.target.value)}
                            className="border rounded-md px-3 py-2 outline-none"
                        >
                            {selecteditems.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.id}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex flex-col">
                        <label htmlFor="problem" className="text-sm font-semibold mb-1 text-blue-950">Issue:</label>
                        <textarea
                            id="problem"
                            value={problem}
                            onChange={(e) => setProblem(e.target.value)}
                            className="border rounded-md px-3 py-2 outline-none"
                        />
                        {errorMessage && (
                            <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
                        )}
                    </div>
                    <div className="justify-between flex">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md transition duration-300"
                        >
                            Submit
                        </button>
                        <button
                            onClick={() => setnewTicketView(false)}
                            className="bg-blue-800 text-white px-4 py-2 rounded-md transition duration-300"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
                <div>
                    {sellerMail == undefined ? null : `Seller : ${sellerMail}`}
                </div>
            </div>
            <div class='Requested-items-page overflow-auto'>
                <table class='Requesteditems-table'>
                    <thead className='tableHeader' style={{ top: '0px' }}>
                        <tr>
                            <th className='w-20'>Order ID</th>
                            <th>Issue</th>
                            <th>Seller</th>
                            <th className='w-1/3 min-w-[10em]'>Reply</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Tickets.map((item) => (
                            <tr key={item.id} class='RetdItem'>
                                <td >
                                    <strong className='w-full text-center'>{item.OrderID}</strong>
                                </td>
                                <td>
                                    {item.Problem}
                                </td>
                                <td>
                                    <strong>{item.Seller}</strong>
                                </td>
                                <td>
                                    {item.Reply}
                                </td>
                                <td>
                                    <select
                                        class="statusdropdown"
                                        id="status"
                                        name="status"
                                        value={item.Status}
                                        onChange={(e) => {
                                            updateStatus(item.id, e.target.value);
                                        }}
                                    >
                                        <option value='Open'>Open</option>
                                        <option value='Close'>Close</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Raiseticket;
