import React, { useState, useEffect } from 'react';
import supabaseClient from '../../supabaseClient';
import axios from 'axios';
import LoadingIcons from 'react-loading-icons'

const SingleItem = ({ User }) => {

    const [imageLinks, setImageLinks] = useState(false); // State to store the array of image links
    const [imageData, setImageData] = useState([]);

    const user = User[0];
    const IntiFormData = {
        ItemCode: '',
        Name: '',
        Price: 0,
        Color: '',
        Discount: 0,
        Description: '',
        Size: '',
        Rating: '',
        WebLink: '',
        Gender: '',
        Category: '',
        Priority: '',
        NoOfDeals: 0,
        DealType: '',
        Percentageofcashabck: 0,
        ImageLink: ''
    }

    const [formData, setFormData] = useState(IntiFormData);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const additem = async () => {
        const ItemCode = formData.ItemCode;
        const Name = formData.Name;
        const Price = formData.Price;
        const Discount = formData.Discount;
        const Description = formData.Description;
        const Size = formData.Size;
        const WebLink = formData.WebLink;
        const Gender = formData.Gender;
        const Category = formData.Category;
        const Priority = formData.Priority;
        const NoDeals = formData.NoOfDeals;
        const ImageLink = imageData[0];
        const SellerName = user.FirstName + ' ' + user.LastName;
        const SellerMail = user.MailID;
        const DealType = formData.DealType;
        const Percentageofcashabck = formData.Percentageofcashabck;

        const { data, error } = await supabaseClient
            .from('Items')
            .upsert([{ ItemCode, Name, Price, Discount, Description, Size, WebLink, Gender, Category, Priority, ImageLink, SellerName, SellerMail, NoDeals, DealType, Percentageofcashabck }], { onConflict: ['id'] });

        if (error) {
            console.error('Error adding item:', error);
        } else {
            addimages();
            alert("Uploaded Successfully")
        }
    }
    const addimages = async () => {
        imageData.map(async (img, rowIndex) => {
            const ItemCode = formData.ItemCode;
            const ImageLink = img;
            const { data, error } = await supabaseClient
                .from('ItemImages')
                .upsert([{ ItemCode, ImageLink }], { onConflict: ['id'] });

            if (error) {
                console.error('Error adding image:', error.message);

            } else {

                setFormData(IntiFormData);
                setImageLinks([])
            }
        });

    };

    const handleSubmit1 = async (e) => {

        e.preventDefault();
        const validationErrors = {};
        const { name, value } = e.target;
        let error = '';

        if (!formData.Name || !formData.Name.trim()) {
            validationErrors.Name = 'Deal title is required';
        }

        if (!formData.ItemCode || !formData.ItemCode.trim()) {
            validationErrors.ItemCode = 'Deal Name is required';
        }
        if (!formData.Gender === '') {
            validationErrors.Gender = 'Please select a Gender.';
        }
        if (!formData.Description || !formData.Description.trim()) {
            validationErrors.Description = 'Description is required';
        }
        if (!formData.WebLink || !formData.WebLink.trim()) {
            validationErrors.WebLink = 'ProductLink is required';
        }
        if (!formData.NoOfDeals || !formData.NoOfDeals.trim()) {
            validationErrors.NoOfDeals = 'TotalOrders is required';
        }
        if (!formData.DealType || !formData.DealType.trim()) {
            validationErrors.DealType = 'DealType is required';
        }
        if (!formData.Percentageofcashabck || !formData.Percentageofcashabck.trim()) {
            validationErrors.Percentageofcashabck = 'Amount to be refunded is required';
        }
        if (!formData.Price || !formData.Price.trim()) {
            validationErrors.Price = 'Price is required';
        }

        setErrors(validationErrors);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        if (Object.keys(validationErrors).length === 0) {
            // Perform your form submission logic here
            if (imageData.length == 0) {
                alert('Please wait till the images are uploaded');
            } else {
                additem();
            }
        }
    };

    useEffect(() => {
        console.log('Product Url', formData.WebLink);
        setImageLinks(true);
        setImageData([]);
        const maxRetries = 3;
        let retryCount = 0;
    
        const fetchData = async () => {
            try {
                const url = formData.WebLink;
    
                const response = await axios.post(
                    'https://imagesgetting-1.onrender.com/images-data',
                    { url },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
    
                const filteredImages = (response.data.imageUrls || []).filter(
                    imageUrl => !imageUrl.toLowerCase().endsWith('.gif')
                )
                setImageData(filteredImages);
                return { images: filteredImages };
    
            } catch (error) {
                console.error('Error fetching data from server:', error.message);
                // Retry if the maximum number of retries hasn't been reached
                if (retryCount < 15 + maxRetries && formData.WebLink !== '') {
                    retryCount++;
                    console.log(`Retrying... Attempt ${retryCount} of ${maxRetries}`);
                    // Retry after a delay (e.g., 100 milliseconds)
                    setTimeout(fetchData, 1000);
                } else {
                    console.error('Max retry attempts reached. Giving up.');
                }
            }
        };
        if (formData.WebLink !== '') {
            fetchData();
        }
    }, [formData.WebLink]);
    
    return (
        <>
            <div className=" mx-auto  max-w-5xl mt-10 ">
                <form className=" p-4  rounded shadow-lg bg-[#f8fafbd8] ">
                    <h2 className="text-2xl font-semibold mb-6 text-center text-blue-950">Single Items Form</h2>

                    <div className="flex flex-wrap lg:gap-5 lg:ml-24 ml-20">
                        <div >
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="ItemCode">
                                    ASIN
                                </label>
                                <input
                                    type="text"
                                    id="ItemCode"
                                    name="ItemCode"
                                    value={formData.ItemCode}
                                    onChange={handleChange}

                                    className={`border ${errors.ItemCode ? 'border-red-500' : 'border-gray-300'
                                        } p-0 w-full rounded-md`}
                                />
                                {errors.ItemCode && <p className="text-red-500 text-sm absolute mt-0 ">{errors.ItemCode}</p>}
                            </div>

                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="Name">
                                    Deal Title
                                </label>
                                <input
                                    type="text"
                                    id="Name"
                                    name="Name"
                                    value={formData.Name}
                                    onChange={handleChange}

                                    className={`border ${errors.Name ? 'border-red-500' : 'border-gray-300'
                                        } p-0 w-full rounded-md`}
                                />
                                {errors.Name && <p className="text-red-500 text-sm absolute mt-0 ">{errors.Name}</p>}
                            </div>


                        </div>
                        <div>
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="Description">
                                    Deal Description
                                </label>
                                <input
                                    type="text"
                                    id="Description"
                                    name="Description"
                                    value={formData.Description}
                                    onChange={handleChange}

                                    className={`border ${errors.Description ? 'border-red-500' : 'border-gray-300'
                                        } p-0 w-full rounded-md`}
                                />
                                {errors.Description && <p className="text-red-500 text-sm absolute mt-0">{errors.Description}</p>}
                            </div>
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="DealType">
                                    Deal Type
                                </label>
                                <select
                                    type="Dropdown"
                                    id="DealType"
                                    name="DealType"
                                    value={formData.DealType}
                                    onChange={handleChange}
                                    className={`border ${errors.DealType ? 'border-red-500' : 'border-gray-300'
                                        } p-0 w-full rounded-md`}
                                >
                                    <option value={null}>Select Type</option>
                                    <option value="Rating only">Rating Only</option>
                                    <option value="Rating & review">Rating & review</option>
                                    <option value="High Discount">High Discount</option>
                                    <option value="Only order">Only Order</option>

                                </select>
                                {errors.DealType && <p className="text-red-500 text-sm absolute mt-0">{errors.DealType}</p>}
                            </div>


                        </div>
                        <div>
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="WebLink">
                                    Product Link
                                </label>
                                <input
                                    type="text"
                                    id="WebLink"
                                    name="WebLink"
                                    value={formData.WebLink}
                                    placeholder="Enter Product Link"
                                    onChange={handleChange}

                                    className={`border ${errors.WebLink ? 'border-red-500' : 'border-gray-300'
                                        } p-0 w-full  rounded-md`}
                                />
                                {errors.WebLink && <p className="text-red-500 text-sm absolute mt-0">{errors.WebLink}</p>}
                            </div>
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="Percentageofcashabck">
                                    Amount to be refunded
                                </label>
                                <input
                                    type="number"
                                    id="Percentageofcashabck"
                                    name="Percentageofcashabck"
                                    value={formData.Percentageofcashabck}
                                    min={0}
                                    max={100}
                                    onChange={handleChange}

                                    className={`border ${errors.Percentageofcashabck ? 'border-red-500' : 'border-gray-300'
                                        } p-0 w-full rounded-md`}
                                />
                                {errors.Percentageofcashabck && <p className="text-red-500 text-sm absolute mt-0">{errors.Percentageofcashabck}</p>}
                            </div>
                        </div>
                        <div>
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="NoOfDeals">
                                    Total Orders
                                </label>
                                <input
                                    type="number"
                                    id="NoOfDeals"
                                    name="NoOfDeals"
                                    value={formData.NoOfDeals}
                                    onChange={handleChange}

                                    className={`border ${errors.NoOfDeals ? 'border-red-500' : 'border-gray-300'
                                        } p-0 w-full rounded-md`}
                                />
                                {errors.NoOfDeals && <p className="text-red-500 text-sm absolute mt-0">{errors.NoOfDeals}</p>}
                            </div>
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2 " htmlFor="Price">
                                    Price
                                </label>
                                <input
                                    type="number"
                                    id="Price"
                                    name="Price"
                                    value={formData.Price}
                                    onChange={handleChange}

                                    className={`border ${errors.Price ? 'border-red-500' : 'border-gray-300'
                                        } p-0 w-full rounded-md`}
                                />
                                {errors.Price && <p className="text-red-500 text-sm absolute mt-0">{errors.Price}</p>}
                            </div>
                        </div>

                        {/* Images DIV */}
                        <div className={`flex gap-2 w-full ${formData.WebLink.length>0 ? null : 'hidden'}`}>
                            <div className='w-full'>
                                <label className='block text-blue-950 text-sm font-bold '>Images :{}</label>
                                {imageData.length==0 ? <div className='flex justify-center p-4 bg-[#ededed]'><LoadingIcons.SpinningCircles    stroke="#ef5757" /></div> :
                                    <div className='flex flex-wrap'>
                                        {imageData.map((link, index) => (< >
                                            <img src={link} className='m-2 w-24' key={link} />
                                        </>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <hr className="border-t  border-gray-400 w-full mt-1" />
                    <h2 className="text-2xl font-semibold mb-6 ml-20 text-blue-950">Optional</h2>

                    <div className="flex flex-wrap lg:gap-5 lg:ml-24 ml-20">
                        <div >
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="Category">
                                    Category
                                </label>
                                <select
                                    type="Category"
                                    id="Category"
                                    name="Category"
                                    value={formData.Category}
                                    onChange={handleChange}
                                    className={`border 'border-gray-300'
                                    p-0 w-48 rounded-md`}
                                >
                                    <option >Select Category</option>
                                    <option value="Fashion">Fashion</option>
                                    <option value="Home, Kitchen & Outdoors">Home, Kitchen & Outdoors</option>
                                    <option value="Electronics, Appliances & Accessories">Electronics, Appliances & Accessories</option>
                                    <option value="Books & Entertainment">Books & Entertainment</option>
                                    <option value="Others">Others</option>

                                </select>
                            </div>
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="Size">
                                    Size
                                </label>
                                <select
                                    type="Dropdown"
                                    id="Size"
                                    name="Size"
                                    value={formData.Size}
                                    onChange={handleChange}
                                    className={`border 'border-gray-300'
                                    p-0 w-48 rounded-md`}

                                >
                                    <option value="">Select Size</option>
                                    <option value="XS">XS</option>
                                    <option value="S">S</option>
                                    <option value="M">M</option>
                                    <option value="L">L</option>
                                    <option value="XL">XL</option>
                                    <option value="XXL">XXL</option>
                                </select>
                            </div>

                        </div>
                        <div>
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="Gender">
                                    Gender
                                </label>
                                <select
                                    type="Dropdown"
                                    id="Gender"
                                    name="Gender"
                                    value={formData.Gender}
                                    onChange={handleChange}
                                    className={`border 'border-gray-300'
                                    p-0 w-full rounded-md`}

                                >
                                    <option >Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="Discount">
                                    Discount
                                </label>
                                <input
                                    type="number"
                                    id="Discount"
                                    name="Discount"
                                    value={formData.Discount}
                                    onChange={handleChange}
                                    className={`border 'border-gray-300'
                                    p-0 w-full rounded-md`}
                                />
                            </div>

                        </div>
                        <div>
                            {User[0]?.Type == "Admin" ? <div className="mb-4 relative">
                                <label className="block text-blue-950 text-sm font-bold mb-2" htmlFor="Priority">
                                    Priority
                                </label>
                                <select
                                    type="Priority"
                                    id="Priority"
                                    name="Priority"
                                    value={formData.Priority}
                                    onChange={handleChange}
                                    className={`border 'border-gray-300'
                                    p-0 w-48 rounded-md`}
                                >
                                    <option >Select Priority</option>
                                    <option value="1">High</option>
                                    <option value="2">Medium</option>
                                    <option value="3">Low</option>
                                </select>
                            </div> : null}

                        </div>
                    </div>

                </form>
                <button
                    type="submit"
                    className="bg-blue-500 text-white float-right mt-4 mr-10 py-2 px-4 rounded-md "
                    onClick={handleSubmit1}
                // disabled={imageData.length == 0}
                >
                    Submit
                </button>
            </div>

        </>
    );
};

export default SingleItem;

