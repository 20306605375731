import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabaseClient from '../../supabaseClient';
import './Popup.css'
import { AiOutlineCloseCircle } from "react-icons/ai";

const Popup = ({ handleClose, show, item, User }) => {
  const [selectedimage, setSelectedimage] = useState(item.ImageLink);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true)
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const singleitem = item;
  const navigate = useNavigate();
  const addrequestItem = async (product) => {
    if (User != null) {
      const UserMailID = User.MailID;
      const ItemCode = product.ItemCode;
      const ItemName = product.Name;
      const ItemPrice = product.Price;
      const ItemDescription = product.Description;
      const ItemWebLink = product.WebLink;
      const Archived = "No";
      const Coupon = "efbiiefbppeit";
      const Status = 0;
      const ItemImage = product.ImageLink;
      const SellerMail = product.SellerMail;

      const { data, error } = await supabaseClient
        .from('RequestedItems')
        .upsert([{ UserMailID, ItemCode, ItemName, ItemPrice, ItemDescription, ItemWebLink, Archived, Coupon, Status, ItemImage, SellerMail }], { onConflict: ['id'] });

      if (error) {
        console.error('Error adding user:', error.message);
      } else {

        navigate('/SelectedItems')
      }
    } else {

      alert('Please Login')
    }
  }
  useEffect(() => {
    const fetchimages = async () => {
      setLoading(true);
      try {
        const { data: images, error } = await supabaseClient
          .from("ItemImages")
          .select()
          .eq('ItemCode', singleitem.ItemCode);

        if (error) {
          console.error("Error fetching images:", error.message);
        } else {
          setImages(images);
          setLoading(false)
        }

      } catch (error) {
        console.error("Error:", error.message);
      }
      return images
    };
    fetchimages();
    setSelectedimage(item.ImageLink)
  }, [item])

  return (
    <div>

      <div className={showHideClassName+' backdrop-blur-lg'}>
        <section className="modal-main-item">
          <div className='images'>
            <strong className='overflow-hidden p-1 text-center max-h-14'>{singleitem.Name}</strong>
            <img src={selectedimage?.substring(0, 47) + '.jpg'} className='imageview' />
            <div className="allimages">
              <div>
                {loading ? '' : (images.map((image) => (
                  <img
                    src={image.ImageLink}
                    alt="Image"
                    onClick={() => setSelectedimage(image.ImageLink)}
                    key={image.id}
                    style={selectedimage == image.ImageLink ? { boxShadow: '0 4px 10px rgba(15, 225, 240)' } : null}
                  />
                )))}
              </div>
            </div>
          </div>
          <div className='productsdetails'>
            <div className='flex justify-between'>
              {singleitem.Discount == 0 ? null : <strong>{singleitem.Discount}% OFF</strong>}
              {singleitem.Size ? <div>Size:<span className="font-semibold"> {singleitem.Size}</span></div> : null}
            </div>
            <div className="border-2 border-yellow-400 rounded-md p-1 w-fit">{singleitem.DealType}</div>
            <div className='price'>
              <span className='text-gray-600 font-semibold text-sm'>Get at</span>
             <div> &#8377;{singleitem.Price}</div>
              {User?.Type != 'Join as Seller' ? (
                singleitem.Status == 'Activate' ? <button onClick={() => { addrequestItem(singleitem) }}>Request item</button> : <p style={{ color: 'Red', fontSize: 'Large' }}>Sold Out...</p>
              ) : null}

            </div>
            <div className='flex justify-between flex-wrap'>
              <span className="font-semibold">Retail Price: {Math.floor(100 * singleitem.Price / (100 - singleitem.Discount))}</span>
              <span>Refund amount: {singleitem.Percentageofcashabck}</span>
            </div>
            <strong style={{ color: "#02b8d5" }}>Description</strong>
            <div className='descriprtion'>{singleitem.Description}</div>
            <div ><span className="font-semibold">Seller:</span>{singleitem.SellerName} - {singleitem.SellerMail}  </div>
            <a className='sitebutton' href={singleitem.WebLink} target='blank'>View item in site --{`->`}</a>
          </div>
          <div className='lg:relative lg:right-[-6px] lg:top-[-16px] absolute right-5 top-1 text-2xl'><button onClick={() => { handleClose() }}><b><AiOutlineCloseCircle/></b></button></div>
        </section>
      </div>
    </div>
  )
}

export default Popup;