import React, { useEffect, useState, useMemo, useRef } from 'react';
import SupabaseClient from '../../supabaseClient';
import './RequestedItems.css';
import { MdOutlineFileDownload } from "react-icons/md";
import { useParams } from 'react-router-dom';
import { FaFilter } from "react-icons/fa";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";

const RequestedItems = React.memo(({ User }) => {
  let { itcode,lmt } = useParams();

  const user = User[0];
  const [limit, setLimit] = useState(lmt);
  const itemcode = useRef(itcode);
  const [selecteditems, setSelecteditems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [allDeals, setallDeals] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [viewItemID, setViewItemID] = useState(null);
  const [viewallreqst, setviewallreqst] = useState(null);
  const [filterStatus, setFilterStatus] = useState("Select");
  // const orderimageUrl ='http://reviewdeals.in:8000/storage/v1/object/public/OrderIDImages/';
  const orderimageUrl = `${SupabaseClient.supabaseUrl}/storage/v1/object/public/OrderIDImages/`;
  const orderStatusOptions = ["Deal Claimed", "Order Placed", "Order details not matched", "Review Posted", "Reupload review Screenshot", "Review is completed", "Payment is done"];
  const orderStatusOptionsshow = ["Deal Claimed", "Order Placed", "Order details verified", "Review Posted", "Review Screenshot uploaded", "Review is completed", "Payment is done"];

  const fetchAlldeals = async () => {
    try {

      let query = SupabaseClient
        .from("Items")
        .select()
        .order('id', { ascending: false })
        .range(limit, limit + 9)
      if (filterStatus != "Select") {
        query = query.eq('DealType', filterStatus);
      }
      if (searchTerm.trim() != "") {
        // Include a condition where ItemCode or ItemName contains the searchTerm
        query = query.or(`ItemCode.ilike.%${searchTerm}%,Name.ilike.%${searchTerm}%`);
      }

      if (user?.Type != 'Admin') {
        query = query.eq('SellerMail', user.MailID);
      }
      const { data, error } = await query
      if (error) {
        console.error('Error fetching requested items:', error.message);
      } else {
        setallDeals(data || []);
      }
    } catch (error) {
      console.error('Error fetching requested items:', error.message);
    }
  };
  const fetchSelectedItems = async () => {
    try {

      let query = SupabaseClient
        .from("RequestedItems")
        .select('*')
        .order('id', { ascending: false })
      if (itemcode.current != 'ItemCodeDownlode') {
        query = query.eq('ItemCode', itemcode.current);
      } else {
        // query=query.range(limit, limit + 9)
      }

      if (user?.Type != 'Admin') {
        query = query.eq('SellerMail', user.MailID);
      }
      const { data, error } = await query
      if (error) {
        console.error('Error fetching requested items:', error.message);
      } else {
        setSelecteditems(data || []);

        if (itemcode.current == 'ItemCodeDownlode') {
          downloadCSV(data)
        }
        // downloadCSV(data)
        // console.log(data.length + ' items selected')
      }
    } catch (error) {
      console.error('Error fetching requested items:', error.message);
    }
  };
  const memoizedFetchData = useMemo(() => fetchAlldeals, [
    filterStatus,
    limit,
    User,
    searchTerm
  ]);
  const updatestatus = async (currentItemId, status) => {
    // Update the 'Order ID' column in the 'RequestedItems' table
    const { data: updateData, error: updateError } = await SupabaseClient
      .from('RequestedItems')
      .update({ Status: status, })
      .eq('id', currentItemId);

    if (updateError) {
      console.error('Error updating Order ID:', updateError.message);
    } else {
      console.log('Order ID updated successfully:', updateData);
      fetchSelectedItems();
      // Additional logic if needed after successful update
    }
  }
  const imageDownload = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'downloaded_image.jpg';
    link.click();
  };
  const downloadCSV = (data) => {
    const csv = convertToCSV(data);
    console.table(csv);
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    const filename = `downloaded_data_${timestamp}.csv`;
    const csvData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    const link = document.createElement('a');
    link.setAttribute('href', csvData);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (data) => {
    // Define fixed headers based on the sample data, excluding "ItemDescription" and "ItemImage"
    const headers = Object.keys(data[0]).filter(header => header !== "ItemDescription" && header !== "ItemImage");
  
    // Add "OrderSS" to the headers if "Order ID" exists
    if (headers.includes("Order ID")) {
      headers.splice(headers.indexOf("Order ID") + 1, 0, "OrderSS");
    }
  
    // Add "ReviewSS" to the headers
    headers.push("ReviewSS");
  
    // Define order status options
    const orderStatusOptionsShow = ["Deal Claimed", "Order Placed", "Order details verified", "Review Posted", "Review Screenshot uploaded", "Review is completed", "Payment is done"];
  
    let csv = headers.join(',') + '\n';
  
    // Create CSV rows based on the headers
    data.forEach(obj => {
      // Convert Status value to corresponding string from orderStatusOptionsShow
      const statusValue = orderStatusOptionsShow[obj["Status"]] || '';
  
      // Extract ItemName value up to the first special character (',' or '.')
      let itemNameValue = obj["ItemName"] || '';
      if (itemNameValue && (itemNameValue.includes(',') || itemNameValue.includes('.'))) {
        itemNameValue = itemNameValue.split(/[,\.]/)[0];
      }
  
      // Construct dynamic URL for "OrderSS" if "Order ID", "UserMailID", and "ItemCode" exist
      let orderSSValue = '';
      if (obj["Order ID"] && obj["UserMailID"] && obj["ItemCode"]) {
        orderSSValue = `${orderimageUrl}${obj["UserMailID"]}/${obj["ItemCode"]}/${obj["Order ID"]}.jpg`;
      }
  
      // Construct dynamic URL for "ReviewSS" only if "ReviewURL" is null and Status value is >= 3
      let reviewSSValue = '';
      if (!obj["ReviewURL"] && obj["Status"] >= 3 && obj["Order ID"] && obj["UserMailID"] && obj["ItemCode"]) {
        reviewSSValue = `${orderimageUrl}${obj["UserMailID"]}/${obj["ItemCode"]}/review/${obj["Order ID"]}.jpg`;
      }
  
      // Construct CSV row based on the headers
      const row = headers.map(header => {
        if (header === "Status") {
          return statusValue; // Status value as human-readable text
        } else if (header === "OrderSS") {
          return orderSSValue; // Dynamic URL for OrderSS
        } else if (header === "ReviewSS") {
          return reviewSSValue; // Blank or dynamic URL for ReviewSS
        } else if (header === "ItemName") {
          return itemNameValue; // Modified ItemName
        } else {
          return obj[header] || ''; // Default value or empty string
        }
      }).join(',');
  
      csv += row + '\n'; // Append CSV row
    });
  
    return csv; // Return the final CSV content
  };
  

  const handleReadMore = (itemId) => {
    setExpandedItems((prev) => ({ ...prev, [itemId]: !prev[itemId] }));
  };

  const PrvItem = () => {
    if (limit >= 10) {
      setLimit(limit - 10)
    }
  }
  const nextItem = () => {
    if (allDeals.length >= 10) {
      setLimit(limit + 10)
    }
  }
  const prvItemStatus = (id) => {
    if (viewItemID == id) {
      setViewItemID(null)
    } else {
      setViewItemID(id)
    }
  }
  const prvallitem = (id) => {
    if (viewallreqst == id) {
      setviewallreqst(null)
    } else {
      setviewallreqst(id)
    }
  }

  useEffect(() => {
    setLimit(0);
  }, [filterStatus]);
  useEffect(() => {
    memoizedFetchData(); // Call the memoized fetchData function
  }, [memoizedFetchData]);
  useEffect(() => {
    fetchSelectedItems()
  }, [viewallreqst]);
  useEffect(() => {
    if (itcode != 'ItemCode') {
      itemcode.current = itcode;
      setviewallreqst(itcode);
    }
    setLimit(lmt)
  }, []);
  return (
    <div class='Requested-items-page overflow-auto'>
      <div className='flex justify-between flex-wrap gap-y-2'>
        <div className='flex lg:w-1/4 w-full md:w-1/3'>
          <label htmlFor="filter" className='flex items-center'><FaFilter className='text-xs items-center' /> &nbsp;Deal type: &nbsp;</label>

          <select
            class="statusdropdown border bg-gray-50 rounded-lg px-3 py-2 flex-grow"
            id="filter"
            name="filter"
            value={filterStatus}
            onChange={(e) => {
              setFilterStatus(e.target.value);
            }}
          >
            <option value='Select'>Select</option>
            <option value='Rating only'>Rating only</option>
            <option value='Rating & Review'>Rating and Review</option>
            <option value='High Discount'>High Discount</option>
            <option value='Only order'>Only order</option>
            <option value='Empty box'>Empty box</option>
          </select>
        </div>
        <div className='lg:w-1/4 w-full md:w-1/3'>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search..."
            className="search-input border bg-gray-50 rounded-lg px-3 py-2 w-full"
          />
        </div>
        <div className='lg:justify-end md:justify-end sm:justify-end flex justify-between w-full lg:w-fit md:w-fit'>
          <button className={`p-2 mr-3 rounded-lg shadow-md`} style={{ backgroundColor: 'rgb(158, 238, 196)' }}
            onClick={() => {
              itemcode.current = 'ItemCodeDownlode';
              fetchSelectedItems();
            }}>
            Download All</button>
          <div className='flex'>
            <button
              className={`bg-yellow-300 text-2xl p-3 rounded-lg shadow-md`}
              disabled={limit == 0}
              onClick={PrvItem} >
              <span class=""><RiArrowLeftSLine /></span>
            </button>
            <div className="bg-black bg-opacity-50 rounded-md mx-2 text-white text-center flex items-center px-4">Page: {limit / 10}</div>
            <button
              class={`bg-yellow-300 text-2xl p-3 rounded-lg shadow-md`}
              disabled={allDeals.length < 10}
              onClick={nextItem}>
              <span class=""><RiArrowRightSLine /></span>
            </button>
          </div>
        </div>
      </div>
      <table class='Requesteditems-table'>
        <thead className='tableHeader' style={{ top: '63px' }}>
          <tr>
            <th>Date</th>
            <th style={{ display: user?.Type == 'Admin' ? '' : 'none' }}>Seller</th>
            <th>Deal Name</th>
            <th className='w-1/4'>Deal Title</th>
            <th className='w-30'>Deal Type</th>
            <th className='w-40'>Refund Amount</th>
            <th className='w-24'>Total Deals</th>
            <th className='w-32'>Deals Claimed</th>
          </tr>
        </thead>
        <tbody>
          {allDeals.map((item, index) => (<>
            <tr key={item.id} class='RetdItem'
              onClick={() => {
                itemcode.current = item.ItemCode;
                prvallitem(item.ItemCode);
              }}>
              <td>{item['Created Date']}</td>
              <td style={{ display: user?.Type == 'Admin' ? '' : 'none' }} >
                <strong>{item.SellerMail}</strong>
              </td>
              <td>
                <a href={item.WebLink} target="_blank"><strong>{item.ItemCode}</strong></a>
              </td>
              <td>{item.Name.substring(0, 35)}...</td>
              <td>{item.DealType}</td>
              <td>{item.Percentageofcashabck}</td>
              <td>{item.NoDeals}</td>
              <td>{item.DealsClamed}</td>
            </tr>
            {viewallreqst == item.ItemCode && itemcode.current == item.ItemCode ?
              <tr className='dealsclamedcss'>
                <td colSpan={8}>
                  {selecteditems.length == 0 ? <div className='w-full text-center'><b>No deals claimed for this item</b></div> :
                    <table class='w-full' >
                      <thead className='tableHeader' style={{ top: '63px' }}>
                        <tr>
                          <th className='w-6'>Buyer</th>
                          <th>Deal Name</th>
                          <th className='w-6'>Deal Title</th>
                          <th className='w-28'>Amount paid</th>
                          <th>Order ID</th>
                          <th>Order Date</th>
                          <th>Order Image</th>
                          <th>Review Image</th>
                          <th className='w-10'>Status</th>
                          {/* <th className='w-10'>Download</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {selecteditems.map((item, index) => (
                          < >
                            <tr key={item.id} class='RetdItem' onClick={() => prvItemStatus(item.id)}  >
                              <td >
                                <strong>{item.UserMailID}</strong>
                              </td>
                              <td >
                                <a href={item.ItemWebLink} target="_blank"> {expandedItems[item.id]
                                  ? item.ItemName
                                  : `${item.ItemName.slice(0, 10)}...`}</a>
                                {item.ItemName.length > 10 && (
                                  <span className="cursor-pointer " onClick={() => handleReadMore(item.id)}>
                                    {expandedItems[item.id] ? ' Read less' : ' Read more'}
                                  </span>
                                )}
                              </td>
                              <td >{item.ItemCode}</td>
                              <td > &#8377; {item.AmountPaid}</td>
                              <td >{item['Order ID']}</td>
                              <td >{item.OrderDate}</td>
                              <td >{item['Order ID'] ? <div>
                                <img src={orderimageUrl + item.UserMailID + '/' + item.ItemCode + '/' + item['Order ID'] + '.jpg'} width={50} />
                                <button onClick={() => { imageDownload(orderimageUrl + item.UserMailID + '/' + item.ItemCode + '/' + item['Order ID'] + '.jpg') }} className='downloadButton'><MdOutlineFileDownload /></button></div> : ''}
                              </td>
                              <td >{item.Status >= 3 ? <div>
                                {item.ReviewURL ? <a href={item.ReviewURL} target='blank'>Review URL</a>: <>
                                  <img src={orderimageUrl + item.UserMailID + '/' + item.ItemCode + '/review/' + item['Order ID'] + '.jpg'} width={50} />
                                  <button onClick={() => { imageDownload(orderimageUrl + item.UserMailID + '/' + item.ItemCode + '/review/' + item['Order ID'] + '.jpg') }} className='downloadButton'><MdOutlineFileDownload /></button>
                                </>
                                }
                              </div> : null}
                              </td>
                              <td >
                                <label htmlFor="status">Select Status:</label><br></br>
                                <select
                                  class="statusdropdown"
                                  id="status"
                                  name="status"
                                  value={item.Status}
                                  onChange={(e) => {
                                    updatestatus(item.id, e.target.value);
                                  }}
                                >
                                  {orderStatusOptions.map((option, index) => (
                                    <option
                                      key={option}
                                      value={index}
                                      style={{ display: `${option === "Deal Claimed" || option === "Order Placed" || option === "Review Posted" ? 'none' : ''}` }}
                                    >
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                            {viewItemID == item.id ? (
                              <tr key={`expanded_${index}`} className="falling">
                                <td colSpan={user?.Type == 'Admin' ? 9 : 8}>
                                  <div className="button-bar flex justify-around">
                                    {orderStatusOptionsshow.map((option, index) => (
                                      <button
                                        key={option}
                                        className={`button prev px-2 ${item.Status === index ? 'selected' : ''} m-2  ${index === 2 || index === 4 ? 'hidden' : ''}`}
                                        style={{
                                          backgroundColor:
                                            index > item.Status ? 'gray' : 'rgb(161, 232, 246)',
                                          color: index > item.Status ? '#ffffff' : null,

                                        }}
                                      >
                                        {option}
                                      </button>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ))}

                      </tbody>
                    </table>

                  }
                  {selecteditems.length != 0 && <div className='w-full flex justify-end p-1'>
                    <button className={`p-1 rounded-lg shadow-md`} style={{ backgroundColor: 'rgb(158, 238, 196)' }}
                      onClick={() => {
                        downloadCSV(selecteditems)
                      }}>
                      Download</button>
                  </div>}
                </td>
              </tr>
              : null}

          </>
          ))}
        </tbody>
      </table>
    </div>

  );
});

export default RequestedItems;