import React, { useEffect, useState } from 'react';
import SupabaseClient from '../../supabaseClient';
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

const UsersData = ({ User }) => {
  const navigate = useNavigate();
  if (User?.Type != 'Admin') {
    navigate('/');
  }
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedusers, setSelectedusers] = useState([]);
  const [limit, setLimit] = useState(0);
  const checkUsersData = async () => {
    let query = SupabaseClient
    .from('Users')
    .select('*')
    .order('id', { ascending: false })
    .range(limit, limit + 9)
    if (searchTerm.trim() != "") {
      // Include a condition where ItemCode or ItemName contains the searchTerm
      query = query.or(`MailID.ilike.%${searchTerm}%,FirstName.ilike.%${searchTerm}%,LastName.ilike.%${searchTerm}%`);
  }
    const { data, error } = await query
    if (error) {
      console.error('Error checking Google ID:', error.message);
    } else {
      if (data.length > 0) {

        setSelectedusers(data);
      }
    }
  };
  const updateUserType = async (userId, newType) => {
    const { error } = await SupabaseClient
      .from("Users")
      .update({ Type: newType })  // Set the new value for 'Type'
      .eq("id", userId);  // Filter where 'id' equals userId

    if (error) {
      console.error("Error updating user type:", error.message);
    } else {
      console.log("User type updated successfully.");
      checkUsersData();
    }
  };

  const PrvItem = () => {
    if (limit >= 10) {
      setLimit(limit - 10)
    }
  }
  const nextItem = () => {
    if (selectedusers.length >= 10) {
      setLimit(limit + 10)
    }
  }
  useEffect(() => {
    checkUsersData()
    // console.log(SupabaseClient.realtime.accessToken)
  }, [limit,searchTerm])

  return (
    <div class='Requested-items-page overflow-auto'>
      <div className='flex justify-between flex-wrap gap-2'>
      <div className='lg:w-1/4 w-full md:w-1/3'>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
          className="search-input border bg-gray-50 rounded-lg px-3 py-2 w-full"
        />
      </div>
      <div className='flex justify-end'>
        <button className={`bg-yellow-300 text-2xl p-3 rounded-lg shadow-md`}
          disabled={limit == 0}
          onClick={PrvItem} >
          <span class=""><RiArrowLeftSLine /></span>
        </button>
        <div className="bg-black bg-opacity-50 rounded-md mx-2 text-white text-center flex items-center px-4">Page: {limit / 10}</div>
        <button className={`bg-yellow-300 text-2xl p-3 rounded-lg shadow-md`}
          disabled={selectedusers.length < 10}
          onClick={nextItem}>
          <span class=""><RiArrowRightSLine /></span>
        </button>
      </div>
      </div>
      <table class='Requesteditems-table'>
        <thead className='tableHeader' style={{ top: '0px' }}  >
          <tr >
            <th className="p-4">Mail-Id</th>
            <th className="p-4">Full Name</th>
            <th className="p-4">Phone</th>
            <th className="p-4">House (or) village</th>
            <th className="p-4">City</th>
            <th className="p-4">State</th>
            {/* <th className="p-4">Country</th> */}
            <th className="p-4">Pin Code</th>
            <th className="p-4">Type</th>
            <th className="p-4">Age</th>
            <th className="p-4">UPI-ID</th>
          </tr>
        </thead>
        <tbody>
          {selectedusers.map((user) => (
            <tr key={user.id} class='RetdItem'>
              <td className="p-3">{user.MailID}</td>
              <td className="p-3">{user.FirstName} {user.LastName}</td>
              <td className="p-3">{user.Phone}</td>
              <td className="p-3">{user.HouseVillage}</td>
              <td className="p-3">{user.City}</td>
              <td className="p-3">{user.State}</td>
              {/* <td className="p-3">{user.Country}</td> */}
              <td className="p-3">{user.PinCode}</td>
              <td className="p-3">
                <div>
                  <select
                    id="roleSelect"
                    onChange={(e) => updateUserType(user.id, e.target.value)}
                    defaultValue={user.Type}
                    disabled={user.MailID == User.MailID}
                  >
                    <option >Join as Shopper</option>
                    <option>Admin</option>
                    <option >Join as Seller</option>
                  </select>
                </div>
              </td>
              <td className="p-3">{user.Age}</td>
              <td className="p-3">{user.UPIID}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>


  )
}

export default UsersData
