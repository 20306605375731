import { useEffect, useState } from 'react';
import supabaseClient from '../../supabaseClient';
import * as loader from 'react-loader-spinner';

const PasswordReset = ({ closePop }) => {
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePasswordReset = async () => {
        setLoading(true)
        try {
            const { data, error } = await supabaseClient.auth.updateUser({
                password: newPassword,
            });

            if (error) {
                if (error.message === 'Email rate limit exceeded') {
                    alert('Email rate limit exceeded. Please try again later.');
                } else {
                    alert('An error occurred during password reset. Please try again later.');
                }
            } else {
                alert('Password updated successfully!');
                closePop();
            }
            setLoading(false)
        } catch (error) {
            console.error('Error updating password:', error.message);
            alert('There was an error updating your password.');
            setLoading(false)
        }
    };
    const formDiv = "mb-4 w-full lg:w-[15em] m-2";
    const formLabel = "block font-bold";
    const formInput = "border bg-gray-50 rounded-lg px-3 py-2 w-full ";
    return (
        <div className='w-full'>

            <div className='flex flex-col items-center'>
                <h2 className='text-xl font-bold'>Reset Your Password</h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handlePasswordReset();
                    }}
                    className='flex flex-col'
                >
                    <div className={formDiv}>
                        <label htmlFor="newPassword" className={formLabel}>New Password:</label>
                        <input
                            type="password"
                            id="newPassword"
                            value={newPassword}
                            required
                            minLength={6}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className={formInput}
                        />
                    </div>
                    <div className='flex justify-center'>
                        <button type="submit" className="transition-transform transform hover:scale-110 text-black p-2 px-4 bg-yellow-300 rounded-lg mx-2"
                        >
                            {loading ? <loader.ThreeDots
                                height="30"
                                width="30"
                                radius="9"
                                color="white"
                                ariaLabel="loading"
                            /> : "Reset Password"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PasswordReset;
