import React from 'react'
import { useState, useEffect } from 'react';
import Googlelogin from '../Login/GoggleAuth.jsx'
import { Link } from 'react-router-dom';
import Profile from './../Images/Profile.jpg'
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { FaHome, FaCartPlus } from "react-icons/fa";
import UpriverLogo from '../Images/Upriver-logo-background remove-copy.png'
import { useLocation } from 'react-router-dom';

const Navbar = React.memo(({ User }) => {
    
    const [click, setClick] = useState(false);
    const [userType, setUserType] = useState('Not Loged in')
    const [isDropdownOpened, setDropdownOpened] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [dataFromChild, setDataFromChild] = useState([]);
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const location = useLocation();

    const handleDropdown = () => {
        setDropDownOpen(!dropDownOpen);
    }

    const togglenavbar = () => {
        setClick(!click)
    }
    const handleAdddealsOpened = () => {
        setDropdownOpened(!isDropdownOpened);
    }

    const handleCloseDeals = () => {
        setDropdownOpened(false);
    }

    const handleDataFromChild = (data) => {
        setDataFromChild(data);

    };

    useEffect(() => {
        if (User[0] != null) {
            setUserType(User[0].Type)
        }
    }, [User])
    useEffect(() => {
        if (User[0] != null) {
            setLoggedIn(true)
        } else {
            setLoggedIn(false)
        }
    }, [User])

    return (
        <div >
            <nav className=" flex items-center shadow-md justify-between  top-0 w-full mx-auto fixed z-50"
                style={{ background: ' white' }} >
               
                <div className=" mx-auto w-full px-4 sm:px-6 ">
                    <div className="flex items-center justify-between  lg:h-16 h-10" id='navbar'>
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <Link to='/'>
                                    <img src={UpriverLogo} alt="Upriverlogo" className=" lg:h-14 h-10" />
                                </Link>
                            </div>
                            {/* {userType == 'Join as Seller'||location.pathname!='/'?null:<div className="ml-4">
                                <Link to='/SellerPage'>
                                    Become a Seller
                                </Link>
                            </div>} */}
                            
                        </div>

                        <div className="hidden md:block" >
                            <div className="flex items-center space-x-4">
                                <div className="hidden md:block">
                                    <div className="flex items-center space-x-4">
                                        <div className="group">
                                            <Link to='/'  onClick={handleCloseDeals}>
                                                <button className={`transition-transform transform hover:scale-110 ${location.pathname=='/'?'text-blue-500':'text-black'} text-2xl relative`}>
                                                    <FaHome />
                                                    <span className="hidden group-hover:block absolute top-full left-1/2 transform -translate-x-1/2 bg-white text-black border border-gray-400 p-1 text-xs rounded mt-2 whitespace-nowrap">Home</span>
                                                </button>
                                            </Link>
                                        </div>
                                        {isLoggedIn ? (
                                            <div className='flex space-x-4 ' >
                                                {userType != 'Join as Seller' ? <div className={location.pathname=='/SelectedItems'?'border-b-2 border-blue-600':''}><Link to='/SelectedItems' onClick={handleCloseDeals}>
                                                    <button className={`transition-transform transform hover:scale-110  ${location.pathname=='/SelectedItems'?'text-blue-500':'text-black'}`} >My Orders
                                                   
                                                    </button>
                                                </Link></div> : null}
                                                {userType == 'Join as Seller' || userType == 'Admin' ? <>

                                                    <div  className={`flex-col ${location.pathname=='/SingleItem'||location.pathname=='/MultiItems'?'border-b-2 border-blue-600':''}`}>
                                                        <button className={` transition-transform transform hover:block hover:scale-110 group ${location.pathname=='/SingleItem'||location.pathname=='/MultiItems'?'text-blue-400':'text-black'}`}onClick={handleAdddealsOpened} style={{ display: `${userType == 'Join as Seller' || userType == 'Admin' ? '' : 'none'}` }}>
                                                            Add Deals
                                                        </button>
                                                        {isDropdownOpened && (
                                                            <div className="flex flex-col bg-white absolute mt-3 p-2 rounded-md border border-gray-200 shadow-lg">
                                                                <Link to="/SingleItem" > <button onClick={handleCloseDeals}
                                                                    className={`text-black flex hover:bg-yellow-300 hover:rounded-md p-1 text-sm`}>
                                                                     Single Item                                                   
                                                                </button>
                                                                </Link>
                                                                <Link to="/MultiItems" ><button onClick={handleCloseDeals}
                                                                   className={`text-black flex hover:bg-yellow-300 hover:rounded-md p-1 text-sm`}>
                                                                    Multiple Items
                                                                </button></Link>
                                                            </div>
                                                        )}
                                                    </div>
                                                   
                                                    <Link to='/RequestedItems/ItemCode/0 'className={`${location.pathname.substring(0,15)=='/RequestedItems'?'border-b-2 border-blue-400':''}`} >
                                                        <button className={`transition-transform transform hover:scale-110 text-black ${location.pathname.substring(0,15)=='/RequestedItems'?'text-blue-500':'text-black'}`}  onClick={handleCloseDeals}>Requested Items
                                                       
                                                        </button>
                                                    </Link>
                                                    <Link to='/DealsAdded' className={`${location.pathname=='/DealsAdded'?'border-b-2 border-blue-400':''}`} >
                                                        <button  className={`transition-transform transform hover:scale-110  ${location.pathname=='/DealsAdded'?'text-blue-500':'text-black'}`}  onClick={handleCloseDeals}>Deals Added
                                                       
                                                        </button>
                                                    </Link>
                                                </> : null}
                                                {userType == 'Admin' ? <> <Link to='/Viewticket' className={`${location.pathname=='/Viewticket'?'border-b-2 border-blue-400':''}`}>
                                                    <button className={`transition-transform transform hover:scale-110  ${location.pathname=='/Viewticket'?'text-blue-500':'text-black'}`}  onClick={handleCloseDeals}>Tickets
                                                   
                                                    </button>
                                                </Link>
                                                    <Link to='/UsersData' className={`${location.pathname=='/UsersData'?'border-b-2 border-blue-400':''}`}>
                                                        <button className={`transition-transform transform hover:scale-110  ${location.pathname=='/UsersData'?'text-blue-500':'text-black'}`}  onClick={handleCloseDeals}>Users Data
                                                       
                                                        </button>
                                                        </Link>
                                                </> : null}
                                            </div>
                                        ) : (
                                            null
                                        )}
                                        <Googlelogin userlogout={() => { setLoggedIn(false) }} />
                                        {isLoggedIn ? (
                                            <p className="text-black transition-transform transform hover:scale-110">
                                                <Link to="/ProfileDetails" className='group'>
                                                    {localStorage.getItem('profile')!=null ? (
                                                        // <img src={JSON.parse(localStorage.getItem('profile')).picture} alt="Profile" className="w-10 rounded-full" />
                                                        // <div className="w-10 rounded-full bg-green-300 p-1 text-center font-bold text-2xl">{User[0]?.FirstName.substring(0,1)}</div>
                                                        <img src={Profile} alt="Avatar" className="w-10 rounded-full" />

                                                    ) : (
                                                        <img src={Profile} alt="Avatar" className="w-10 rounded-full" />
                                                    )}

                                              
                                                <span className="hidden group-hover:block absolute top-full left-1/2 transform -translate-x-1/2 bg-white border border-gray-400 text-black p-1 text-xs rounded whitespace-nowrap">Profile Details</span>
                                                </Link>
                                            </p>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:hidden flex items-center">
                            <button className="inline-flex items-center justify-center p-2 rounded-md text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                onClick={togglenavbar}
                            >
                                {click ? (
                                    <svg
                                        className="h-6 w-6"
                                        xmlns="http://www.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="h-6 w-6"
                                        xmlns="http://www.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M4 6h16M4 12h16m-7 6h7"
                                        />
                                    </svg>
                                )}
                            </button>

                        </div>
                    </div>
                </div>
                {click && (
                    <div className={`md:hidden  bg-white mt-10  h-screen fixed top-0 left-20 w-full transform ${click ? 'translate-x-0' : 'translate-x-full'} `}>
                        <div className="font-worksans  leading-normal tracking-wide p-4 ">
                            {isLoggedIn ? (
                                <p className="text-white transition-transform transform hover:scale-110 ml-3">
                                    <Link onClick={togglenavbar} to="/ProfileDetails">

                                        {dataFromChild.picture ? (
                                            <img src={dataFromChild.picture} alt="Profile" className="w-10 rounded-full" />
                                        ) : (
                                            <img src={Profile} alt="Avatar" className="w-10 rounded-full" />
                                        )}

                                    </Link>
                                </p>
                            ) : (
                                <div></div>
                            )}

                            <Link onClick={togglenavbar} to="/" className="text-black p-2 ">
                                Deals</Link>
                            <hr className="border-t border-black" />
                            <Googlelogin onDataFromChild={handleDataFromChild} userlogout={() => { setLoggedIn(false) }} />
                            <hr className="border-t border-black" />
                            {isLoggedIn ? (
                                <>
                                    <Link onClick={togglenavbar} to='/SelectedItems' >
                                        <button className="transition-transform transform hover:scale-110 text-black p-2">My Orders</button></Link>
                                    <hr className="border-t border-black" />
                                    <div onClick={handleDropdown} className="text-black " style={{ display: `${userType == 'Join as Seller' || userType == 'Admin' ? '' : 'none'}` }}>
                                        <div className="flex p-2">
                                            Add Deals
                                            <span className=" text-2xl text-black " onClick={handleDropdown} > {dropDownOpen ? <MdArrowDropUp /> : <MdArrowDropDown />}</span>
                                        </div>
                                        {dropDownOpen && (
                                            <div className="flex flex-col border border-black bg-white w-fit rounded-md">
                                                <button onClick={togglenavbar}
                                                    className=" text-black flex p-1 ">
                                                    <Link to="/SingleItem">  Single Item</Link>
                                                </button>
                                                <button onClick={togglenavbar}
                                                    className="text-black flex p-1">
                                                    <Link to="/MultiItems">Multiple Items</Link>
                                                </button> 
                                            </div>
                                        )}
                                    </div>{' '}
                                    <hr className="border-t border-black" />
                                    <div className="flex flex-col">
                                        <Link onClick={togglenavbar} to='/RequestedItems/ItemCode/0'>
                                            <button className="transition-transform transform hover:scale-110 text-black p-2" style={{ display: `${userType == 'Admin' ? '' : 'none'}` }}>Requested Items</button>
                                        </Link>
                                        <hr className="border-t border-black" />
                                        <Link to='/DealsAdded'>
                                            <button className="transition-transform transform hover:scale-110 text-black p-2" style={{ display: `${userType == 'Admin' || userType == 'Join as Seller' ? '' : 'none'}` }}>Deals Added</button>
                                        </Link>
                                        <hr className="border-t border-black" />
                                        <Link to='/Viewticket'>
                                            <button className="transition-transform transform hover:scale-110 text-black p-2" style={{ display: `${userType == 'Admin' || userType == 'Join as Seller' ? '' : 'none'}` }}>Tickets</button>
                                        </Link>
                                        <hr className="border-t border-black " />

                                        <Link to='/UsersData'>
                                            <button className="transition-transform transform hover:scale-110 text-black p-2" style={{ display: `${userType == 'Admin' ? '' : 'none'}` }}>Users Data</button>
                                        </Link>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                )
                }
            </nav >
        </div >
    )
});

export default Navbar;