import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { IoMdDownload } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import supabaseClient from '../../supabaseClient';
import axios from 'axios';
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import LoadingIcons from 'react-loading-icons'

const supabase = supabaseClient;

const MultiItems = ({ User }) => {
    const [excelData, setExcelData] = useState([[]]);
    const [imageData, setImageData] = useState([]);
    const [fileSelected, setFileSelected] = useState(false);
    const [expandedCells, setExpandedCells] = useState([]);
    const [expandImages, setExpandImages] = useState([]);

    const navigate = useNavigate();
    const user = User[0];
    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            // console.log(file)
            const reader = new FileReader();
            setFileSelected(true);
            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    // Assuming the first sheet is the one you want to read
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    // Parse the sheet data
                    const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 })
                        .filter(row => row.filter(cell => cell !== undefined && cell !== null && cell !== '').length > 0);
                    setExcelData(parsedData);
            // console.log(parsedData)

                    alert('Please wait untill the images display')
                } catch (error) {
                    console.error('Error reading the Excel file:', error);
                }
            };

            reader.readAsArrayBuffer(file);
        }
    };
    const additems = async () => {
        excelData.slice(1).map(async (row, rowIndex) => {
            const ItemCode = row[1];
            const Name = row[2];
            const Description = row[3];
            const DealType = row[4];
            const WebLink = row[5];
            const Percentageofcashabck = row[6];
            const NoDeals = row[7];
            const Price = row[8];
            const ImageLink = imageData[rowIndex]?.images[0];
            const Category = row[9];
            const Gender = row[10];
            const Size = row[11];
            const Priority = row[1];
            const Discount = row[13];
            const SellerName = user.FirstName + ' ' + user.LastName;
            const SellerMail = user.MailID;

            const { data, error } = await supabase
                .from('Items')
                .upsert([{ ItemCode, Name, Price, Discount, Description, Percentageofcashabck, Size, WebLink, Gender, Category, ImageLink, SellerName, SellerMail, NoDeals, DealType }], { onConflict: ['id'] });

            if (error) {
                console.error('Error adding user:', error.message);
            } else {
                console.log('User added successfully:', data);
                addimages(rowIndex, ItemCode);
            }
        });
        alert("Uploaded Successfully");
    };
    const addimages = async (imageIndex, itemCode) => {
        imageData[imageIndex]?.images.map(async (row, rowIndex) => {
            const ItemCode = itemCode;
            const ImageLink = row;
            const { data, error } = await supabase
                .from('ItemImages')
                .upsert([{ ItemCode, ImageLink }], { onConflict: ['id'] });

            if (error) {
                console.error('Error adding image:', error.message);
            } else {
                console.log('images add successfully:', data);
            }
        });

    };

    const handleDownload = () => {
        const filename = 'Items.xlsx';
        const filepath = process.env.PUBLIC_URL + '/Excelfile/' + filename;

        const link = document.createElement('a');
        link.href = filepath;
        link.download = filename;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };

    useEffect(() => {
        const MAX_RETRIES = 15;

        const fetchDataWithRetry = async (url, retries = 0) => {
            try {
                const response = await axios.post(
                    'https://imagesgetting-1.onrender.com/images-data',
                    { url },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
        
                if (response.data && response.data.imageUrls) {
                    const filteredImages = response.data.imageUrls
                        .filter(imageUrl => !imageUrl.toLowerCase().endsWith('.gif'))
                        .map(imageUrl => imageUrl.replace('._SX38_SY50_CR,0,0,38,50_', ''))
                        .map(imageUrl => imageUrl.replace('._SS40_', ''))
                        .map(imageUrl => imageUrl.replace('._SS100_', ''));
                    if (filteredImages.length === 0) {
                        return fetchDataWithRetry(url, retries + 1);
                    }
                    return { images: filteredImages };
                } else {
                    console.error('Invalid response from server for URL:', url);
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                console.error(`Error fetching data for URL: ${url}. Retrying... (${retries + 1}/${MAX_RETRIES})`);
        
                if (retries < MAX_RETRIES) {
                    // Retry fetching with an incremented retry count after a 2-second delay
                    await new Promise(resolve => setTimeout(resolve,retries*2000));
                    return fetchDataWithRetry(url, retries + 1);
                } else {
                    // Maximum retries reached, return empty data
                    console.error(`Max retries reached for URL: ${url}`);
                    return { images: [] };
                }
            }
        };
        

        const fetchDataSequentially = async () => {

            try {
                const allImageData = [];

                for (let i = 1; i < excelData.length&&excelData[i].length>=9; i++) {
                // console.log('testing2')

                    const urls = excelData[i];
                 
                        const url = urls[5];
                        console.log(url);
                        const imageData = await fetchDataWithRetry(url);
                  
                    allImageData.push(imageData);
                }
                // console.log(allImageData);

                setImageData(allImageData);

            } catch (error) {
                console.error('Error fetching data from server:', error.message);
            }
        };

        fetchDataSequentially();
    }, [excelData]);

    const toggleCellExpansion = (rowIndex) => {
        setExpandedCells((prevExpandedCells) => {
            const updatedExpandedCells = [...prevExpandedCells];
            const index = updatedExpandedCells.indexOf(rowIndex);

            if (index !== -1) {
                // Remove from expandedCells if already expanded
                updatedExpandedCells.splice(index, 1);
            } else {
                // Add to expandedCells if not expanded
                updatedExpandedCells.push(rowIndex);
            }

            return updatedExpandedCells;
        });
    };


    const handleImages = (rowIndex) => {
        setExpandImages((prev) => ({ ...prev, [`${rowIndex}`]: !prev[`${rowIndex}`] }))
    }

    return (
        <>
            <div className="max-w-4xl mx-auto   p-5  mt-4 rounded-xl bg-[#6cb1cf7d]  ">
                <div className="flex flex-col lg:flex-row lg:justify-between gap-2 ">
                    <label htmlFor="fileInput" className="cursor-pointer p-3 bg-blue-500  max-w-fit text-white rounded-full">
                        Choose File
                    </label>
                    <div className="">
                        <input
                            type="file"
                            accept=".xlsx, .xls"
                            id="fileInput"
                            onChange={handleFileUpload}
                            className="border-none bg-radial-gradient-black-transparent hidden" style={{ width: '200px' }}
                        />
                    </div>
                    <button
                        onClick={handleDownload}
                        className="bg-green-500 hover:bg-blue-700 p-3  max-w-fit text-white font-bold  rounded-full "
                    >
                        <span className="flex"> DownloadFile
                            <span className="mt-1 ml-2"> <IoMdDownload /></span>
                        </span>
                    </button>
                </div>
            </div>

            <div className="overflow-auto">

                {fileSelected && excelData.length > 0 && (
                    <>
                        <div className="mt-3 ">
                            <button
                                onClick={additems}
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-5 "
                            >

                                <div >
                                    Add Items
                                </div>
                            </button>
                        </div>
                        <div className=''>

                            <table class='Requesteditems-table '
                                style={{ background: 'linear-gradient(to right, rgba(125,185,232,1) 19%, rgba(32,124,202,1) 54%, rgba(41,137,216,1) 64%, rgba(30,87,153,1) 100%)' }} >
                                <thead className='tableHeader' style={{ top: '63px' }}>
                                    <tr>
                                        {excelData[0].slice(0,excelData[1][1].length).map((cell, index) => (
                                            <th
                                                key={index}
                                                className="border p-2 text-left font-bold bg-gray-200"
                                            >
                                                {cell}
                                            </th>

                                        ))}
                                        <th className="border p-2 text-left font-bold bg-gray-200">
                                            Images
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelData.slice(1).map((row, rowIndex) => (
                                        <tr key={rowIndex} class={`RetdItem ${row.length >= 9 ? null : 'hidden'}`}>

                                            {row.map((cell, cellIndex) => (
                                                <td
                                                    key={cellIndex}
                                                    className="border p-2 text-left whitespace-no-wrap"
                                                >
                                                    {cellIndex === 3 || cellIndex === 2 &&
                                                        cell.length > 20 ? (
                                                        <>
                                                            <div className='w-40'>
                                                                {expandedCells.includes(rowIndex) ? (
                                                                    cell // Display the full content if expanded
                                                                ) : (
                                                                    // `${cell.substring(0, 20)}...` // Display shortened content with "Read More"
                                                                    typeof cell === 'string' ? cell.slice(0, 20) : cell
                                                                )}
                                                            </div>
                                                            <button onClick={() => toggleCellExpansion(rowIndex)}>
                                                                {expandedCells.includes(rowIndex) ? 'Read Less' : 'Read More'}
                                                            </button>
                                                        </>
                                                    ) : (
                                                        // Display regular content for other cells
                                                        cell
                                                    )}


                                                </td>
                                            ))}
                                            <td >

                                                {imageData.length > 0 ? (
                                                    <>
                                                        <div>
                                                            {expandImages[`${rowIndex}`] ? (
                                                                // Display all images if expanded
                                                                imageData[rowIndex]?.images.map((img, imgindex) => (
                                                                    <img className="w-24" key={img} src={img} />
                                                                ))
                                                            ) : (
                                                                // Display only the first image if not expanded
                                                                <img className="w-24" key={imageData[rowIndex]?.images[0]} src={imageData[rowIndex]?.images[0]} />
                                                            )}
                                                        </div>
                                                        <div
                                                            className="cursor-pointer text-2xl flex justify-center"
                                                            onClick={() => handleImages(rowIndex)}
                                                        >

                                                            {expandImages[`${rowIndex}`] ? <MdExpandLess /> : <MdExpandMore />}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className='flex justify-center p-4'><LoadingIcons.SpinningCircles    stroke="#98ff98" /></div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </>

    );
};

export default MultiItems;