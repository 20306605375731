import React, { useState, useEffect } from 'react';
import supabaseClient from '../../supabaseClient';
import { RiArrowRightSLine,RiArrowLeftSLine } from "react-icons/ri";

const Viewticket = ({ User }) => {
    const user = User[0];
    const [Tickets, setTickets] = useState([]);
    const [replyvalue, setReplyvalue] = useState(null);
    const [ViewID, setViewID] = useState(null);
    const [limit, setLimit] = useState(0);
    const fetchTickets = async () => {
        try {

            let query = supabaseClient
                .from("Tickets")
                .select()
                .range(limit, limit + 9)

            if (user?.Type != 'Admin') {
                query = query.eq('Seller', user.MailID);
            }
            const { data, error } = await query
            if (error) {
                console.error('Error fetching requested items:', error.message);
            } else {
                setTickets(data || []);
            }
        } catch (error) {
            console.error('Error fetching requested items:', error.message);
        }
    };
    const updateStatus = async (itemId) => {
        try {
            // Update the "Status" column in the "Tickets" table for the item with the given itemId
            const { data, error } = await supabaseClient
                .from('Tickets')
                .update({ Reply: replyvalue })
                .eq('id', itemId);

            if (error) {
                throw error;
            }

            console.log('Status updated successfully:', data);
            fetchTickets();
            return data; // Return the updated data
        } catch (error) {
            console.error('Error updating status:', error.message);
            return null;
        }
    }
    const PrvItem = () => {
        if (limit >= 10) {
            setLimit(limit - 10);
        }
    }
    const nextItem = () => {
        if (Tickets.length >= 10) {
            setLimit(limit + 10);
        }
    }
    useEffect(() => {
        fetchTickets();
    }, [User,limit]);
    return (
        <div>
            <div class='Requested-items-page overflow-auto'>
            <div className='lg:flex lg:justify-end md:flex md:justify-end sm:flex sm:justify-end flex justify-end'>
        <button class={`bg-yellow-300 text-2xl p-3 rounded-lg shadow-md ${limit == 0 ? 'hidden' : null}`} onClick={PrvItem} >
          <span class=""><RiArrowLeftSLine /></span>
        </button>
        <button class={`bg-yellow-300 text-2xl p-3 rounded-lg shadow-md ${Tickets.length < 10 ? 'hidden' : null}`} onClick={nextItem}>
          <span class=""><RiArrowRightSLine /></span>
        </button>
      </div>
                <table class='Requesteditems-table'>
                    <thead className='tableHeader' style={{ top: '63px' }}>
                        <tr>
                            <th className='w-10'>Product ID</th>
                            <th className='w-44'>Buyer</th>
                            <th>Issue</th>
                            <th style={{ display: user?.Type == 'Admin' ? '' : 'none' }}>Seller</th>
                            <th className='w-72 min-w-[10em]'>Reply</th>
                            <th>Submit</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Tickets.map((item) => (
                            <tr key={item.id} class='RetdItem'>
                                <td>
                                    <strong>{item.OrderID}</strong>
                                </td>
                                <td>
                                    <strong>{item.Buyer}</strong>
                                </td>
                                <td>
                                    {item.Problem}
                                </td>
                                <td style={{ display: user?.Type == 'Admin' ? '' : 'none' }}>
                                    <strong>{item.Seller}</strong>
                                </td>
                                <td>
                                    <textarea
                                        id="textInput"
                                        type="text"
                                        onDoubleClick={() => {setViewID(item.id);setReplyvalue(item.Reply)}}
                                        value={ViewID === item.id ? replyvalue : (item.Reply || '')}  
                                        readOnly={item.Reply != null||ViewID==item.id ? false : true}
                                        disabled={item.Status=='Close'}
                                        onChange={(e) => {if(ViewID==item.id ){setReplyvalue(e.target.value)}}}
                                        className="border rounded-md px-2 py-2 outline-none w-full h-full"
                                    />
                                </td>
                                <td>
                                    <button onClick={() => updateStatus(item.id)}>
                                        Confirm
                                    </button>
                                </td>
                                <td>
                                    {item.Status}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Viewticket
