import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as loader from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import supabaseClient from '../../supabaseClient';

const Emaillogin = ({ Profile }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [action, setAction] = useState('login');
    const [forgotPass, setForgotPass] = useState(false);
    const [userfound, setUserfound] = useState(false);

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phoneNumber: '',
        pincode: '',
        gender: '',
        state: '',
        upiId: '',
        age: '',
        houseOrVillage: '',
        city: ''
    });
    const statesAndUTs = ["Andaman and Nicobar Islands", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Ladakh", "Lakshadweep", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Puducherry", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmitSignUp = (e) => {
        e.preventDefault();

        supabaseSignup();

    };

    const UpdateUser = async () => {

        const MailID = formData.email;
        const FirstName = formData.firstName;
        const LastName = formData.lastName;
        const Phone = formData.phoneNumber;
        const HouseVillage = formData.houseOrVillage;
        const City = formData.city;
        const State = formData.state;
        // const Country = formData.Country;
        const PinCode = formData.pincode;
        const Age = formData.age;
        const UPIID = formData.upiId;
        const Type = 'Join as Shopper';
        const Gender = formData.gender;

        // Make a query to update the existing GUser based on GoogleID
        const { data, error } = await supabaseClient
            .from('Users')
            .upsert(
                [{ MailID, FirstName, LastName, Phone, HouseVillage, State, City, PinCode, Age, UPIID, Type, Gender }],
                {
                    onConflict: ['MailID'], // Specify the conflict resolution column (GoogleID in this case)
                }
            );

        if (error) {
            console.error('Error updating GUser:', error.message);
        } else {
            // alert('User updated successfully')
            // navigate('/');
            // window.location.reload();
        }
        localStorage.removeItem('formData');
    };
    const supabaseSignup = async () => {
        setLoading(true);
        let { data, error } = await supabaseClient.auth.signUp({
            email: forgotPass ? email : formData.email,
            password: forgotPass ? password : formData.password,
        })
        if (error) {
            console.error(error);
            if (error.message === "Email rate limit exceeded") {
                setErrorMessage("Email rate limit exceeded. Please try again later.");
            } else {
                setErrorMessage("An error occurred during sign-up. Please try again later.");
            }
            setLoading(false);
        } else {
            console.log(data);
            UpdateUser();
            alert('Verfication email has been sent');
            const { data: { user } } = await supabaseClient.auth.getUser();
            Profile(user);
            console.log(user);
            localStorage.setItem('profile', JSON.stringify(user));
            navigate('/');
            setLoading(false);
            window.location.reload();
        }
    }
    const supabaselogin = async () => {
        setLoading(true);

        let { data, error } = await supabaseClient.auth.signInWithPassword({
            email,
            password,
        })
        if (error) {
            console.error(error);
            alert('Invalid login credentials / User not found')
            setLoading(false);

        } else {
            const { data: { user } } = await supabaseClient.auth.getUser()
            Profile(user)
            console.log(user);
            localStorage.setItem('profile', JSON.stringify(user));
            navigate('/ProfileDetails');
            setLoading(false);
            window.location.reload();
        }
    }
    const onSubmit = () => {

        if (forgotPass) {
            forgotPassword();
        } else {
            supabaselogin();

        } // Call login function

    };
    const checkUser = useCallback(async (mail) => {

        try {
            const { data, error } = await supabaseClient
                .from('Users')
                .select('*')
                .eq('MailID', String(mail));

            if (error) {
                console.error('Error checking Google ID:', error.message);
            } else {
                console.log(data);
                if (data.length > 0) {
                    setUserfound(true);
                } else {
                    setUserfound(false);
                }
            }
        } catch (error) {
            console.error('Error checking Google ID:', error.message);
        }
    }, []);
    const forgotPassword = async () => {
        setLoading(true);
        try {
            const { data, error } = await supabaseClient.auth.resetPasswordForEmail(email);
            if (data) {
                alert('A password reset email has been successfully sent to your email address.');
            } else if (error) {
                throw new Error(error.message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error sending password reset email:', error.message);
            //   console.log('Error sending password reset email');
            setLoading(false);
        }
    };

    const formDiv = "mb-4 w-full lg:w-[15em] m-2";
    const formLabel = "block font-bold";
    const formInput = "border bg-gray-50 rounded-lg px-3 py-2 w-full ";
    return (
        <div className='flex items-center flex-col w-full'>
            <h2 className='font-bold text-xl'>{action == 'login' ? forgotPass ? "Reset Password Form" : "Log In" : 'Sign Up'}</h2>
            {action == 'login' ?
                <form onSubmit={handleSubmit(onSubmit)} className="p-2 flex flex-col items-center lg:w-2/5 h-full justify-center modal-main-item-login">
                    <div className="mb-4 w-full">

                        <label htmlFor="email" className="block font-bold">Email</label>
                        <input
                            id="email"
                            type="email"
                            {...register("email", { required: true })}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            className="border rounded-lg  bg-gray-50  px-3 py-2 w-full"
                        />
                        {/* {errors.email && <span className="text-red-500">This field is required</span>} */}
                    </div>

                    {!forgotPass && <div className="mb-4 w-full">
                        <label htmlFor="password" className="block font-bold">{forgotPass ? "Enter Your New Password" : "Password"}</label>
                        <input
                            id="password"
                            type="password"
                            {...register("password", { required: true })}
                            required={!forgotPass}
                            onChange={(e) => setPassword(e.target.value)}
                            className="border bg-gray-50 rounded-lg px-3 py-2 w-full"
                        />
                        {/* {errors.password && <span className="text-red-500">This field is required</span>} */}
                        {password.length < 6 && password.length >= 1 && (
                            <div className='text-red-500'>
                                Password must be at least 6 characters long.
                            </div>
                        )}
                    </div>}
                    <div className='text-red-500'>
                        {errorMessage ? errorMessage : null}
                    </div>
                    <div className="flex justify-between gap-x-8 w-full mb-4 mt-4">

                        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => setAction('login')}>
                            {loading && action === 'login' ? <loader.ThreeDots
                                height="30"
                                width="30"
                                radius="9"
                                color="white"
                                ariaLabel="loading"
                            /> : forgotPass ? "Reset Password" : "Login"}
                        </button>
                        <button type="button" className="transition-transform transform hover:scale-110 text-black p-2 px-4 bg-yellow-300 rounded-lg mx-2"
                            onClick={() => setAction('SignUp')}>
                            Sign Up
                        </button>
                    </div>
                    <div>
                        {forgotPass ? <button type='button' onClick={() => setForgotPass(false)}>Back to Login</button> :
                            <button type='button' onClick={() => setForgotPass(true)}>Forgotten Password</button>}


                    </div>


                </form>  :
                    <form onSubmit={handleSubmitSignUp} className='flex w-full flex-wrap justify-between modal-main-item-login'>
                        <div className='flex w-full flex-wrap justify-between'>
                            <div className={formDiv}>
                                <label className={formLabel}>First Name:</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                    className={formInput}
                                />
                            </div>
                            <div className={formDiv}>
                                <label className={formLabel}>Last Name:</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                    className={formInput}
                                />
                            </div>
                            <div className={formDiv}>
                                <label className={formLabel}>Email:</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className={formInput}
                                    onBlur={(e) => checkUser(e.target.value)}
                                />

                                {userfound && <div className='text-red-500 text-xs'>User already exists. Please go back to the login page.</div>}
                            </div>
                            <div className={formDiv}>
                                <label htmlFor="password" className={formLabel}>Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    name='password'
                                    required
                                    minLength={6}
                                    onChange={handleChange}
                                    className={formInput}
                                />
                            </div>
                            <div className={formDiv}>
                                <label htmlFor="phoneNumber" className={formLabel}>Phone Number:</label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    pattern="[0-9]{10}"
                                    title="Please enter a 10-digit phone number"
                                    required
                                    className={formInput}
                                />
                            </div>
                            <div className={formDiv}>
                                <label htmlFor="pincode" className={formLabel}>Pincode:</label>
                                <input
                                    type="text"
                                    id="pincode"
                                    name="pincode"
                                    value={formData.pincode}
                                    onChange={handleChange}
                                    pattern="[0-9]{6}"
                                    title="Please enter a 6-digit pincode"
                                    required
                                    className={formInput}
                                />
                            </div>
                            <div className={formDiv}>
                                <label className={formLabel}>Gender:</label>
                                <select
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    required
                                    className={formInput}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div className={formDiv}>
                                <label className={formLabel}>State:</label>
                                <select
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    required
                                    className={formInput}
                                >
                                    <option value="">Select State</option>
                                    {statesAndUTs.map((state) => {
                                        return <option value={state} key={state}>{state}</option>
                                    })}
                                    {/* Add options for different states */}
                                </select>
                            </div>
                            <div className={formDiv}>
                                <label className={formLabel}>UPI-ID:</label>
                                <input
                                    type="text"
                                    name="upiId"
                                    value={formData.upiId}
                                    onChange={handleChange}
                                    pattern=".*@.*"
                                    title="Please enter a valid UPI-ID"
                                    required
                                    className={formInput}
                                />
                            </div>
                            <div className={formDiv}>
                                <label className={formLabel}>Age:</label>
                                <input
                                    type="number"
                                    name="age"
                                    value={formData.age}
                                    onChange={handleChange}
                                    required
                                    className={formInput}
                                />
                            </div>
                            <div className={formDiv}>
                                <label className={formLabel}>House(or)Village:</label>
                                <textarea
                                    name="houseOrVillage"
                                    value={formData.houseOrVillage}
                                    onChange={handleChange}
                                    required
                                    className={formInput}
                                ></textarea>
                            </div>
                            <div className={formDiv}>
                                <label className={formLabel}>City:</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    required
                                    className={formInput}
                                />
                            </div>
                        </div>
                        <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => setAction('login')}>
                            Back to Login
                        </button>
                        <button
                            type="submit"
                            disabled={userfound}
                            className="transition-transform transform hover:scale-110 text-black p-2 px-4 bg-yellow-300 rounded-lg mx-2">
                            Sign Up</button>
                    </form>
            }
        </div>
    )
}

export default Emaillogin
