import React, { useState, useEffect } from 'react';
import supabaseClient from '../../supabaseClient';
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import './Toggle.css';
import Editpopup from './EditPopUp';
import { FaFilter } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { getDate } from 'date-fns/getDate';
import { RiDeleteBin6Line } from "react-icons/ri";

const DealsAdded = ({ User }) => {
    const user = User[0];
    const [singleitem, setSingleitem] = useState("");
    const [selecteditems, setSelecteditems] = useState([]);
    const [activeCheckbox, setActiveCheckbox] = useState(false);
    const [approveCheckbox, setApproveCheckbox] = useState(false);
    const [limit, setLimit] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const [filterStatus, setFilterStatus] = useState("Select");
    const [searchTerm, setSearchTerm] = useState('');

    const handlePopupToggle = () => {
        setModalOpen(!isModalOpen);
    };

    const fetchSelectedItems = async () => {
        try {

            let query = supabaseClient
                .from("Items")
                .select()
                .order('id', { ascending: false })
                .range(limit, limit + 9)

            if (user?.Type != 'Admin') {
                query = query.eq('SellerMail', user.MailID);
            }
            if (filterStatus != "Select") {
                query = query.eq('DealType', filterStatus);
            }
            if (activeCheckbox) {
                query = query.eq('Status', 'Activate');
            }
            if (approveCheckbox) {
                query = query.eq('AdminApprove', 'Activate');
            }
            if (searchTerm.trim() != "") {
                // Include a condition where ItemCode or ItemName contains the searchTerm
                query = query.or(`ItemCode.ilike.%${searchTerm}%,Name.ilike.%${searchTerm}%`);
            }
            const { data, error } = await query
            if (error) {
                console.error('Error fetching requested items:', error.message);
            } else {
                setSelecteditems(data || []);
            }
        } catch (error) {
            console.error('Error fetching requested items:', error.message);
        }
    };
    const CheckItemdues = async (code) => {
        try {

            let query = supabaseClient
                .from("RequestedItems")
                .select('*')
                .eq('ItemCode', code)
                .lt('Status', 6)
                .range(0, 1)
                .order('id', { ascending: false })

            const { data, error } = await query
            if (error) {
                console.error('Error fetching requested items:', error.message);
            } else {
                // console.log(data);
                return data.length;
            }
        } catch (error) {
            console.error('Error fetching requested items:', error.message);
        }
    };
    const handleStatusChange = async (status, id, column) => {
        let sts;
        if (status == 'Activate') {
            sts = 'Inactive';
        } else {
            sts = 'Activate';
        }
        try {
            let query = supabaseClient
                .from('Items')
            if (column == 'Status') {
                query = query.update({ Status: sts })
                    .eq('id', id);
            } else {
                query = query.update({ AdminApprove: sts })
                    .eq('id', id);
            }
            const { data, error } = await query
            if (error) {
                console.error('Error updating status:', error.message);
                return;
            } else {
                fetchSelectedItems();
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    const PrvItem = () => {
        if (limit >= 10) {
            setLimit(limit - 10)
        }
    }
    const nextItem = () => {
        if (selecteditems.length >= 10) {
            setLimit(limit + 10)
        }
    }
    const deleteFunction = async (tabel, column, id) => {
        try {
            const { data, error } = await supabaseClient
                .from(tabel)
                .delete()
                .eq(column, id);
            if (error) {
                console.error('Error deleting item:', error.message);
            } else {
                console.log('Item deleted successfull', id);
                if(tabel=='Items'){
                    fetchSelectedItems()
                }
            }
        } catch (error) {
            console.error('Error deleting item:', error.message);
        }
    }
    const deleteItem = async (id) => {
        const dues = await CheckItemdues(id);
        if (dues > 0) {
            alert("This item is already requested by other user");
        } else {
            deleteFunction('ItemImages', 'ItemCode', id)
            deleteFunction('Items', 'ItemCode', id)
            alert("Item deleted successfully");
        }
    }
    useEffect(() => {
        fetchSelectedItems();
    }, [User, limit, isModalOpen, searchTerm, filterStatus, activeCheckbox, approveCheckbox]);
    useEffect(() => {
        setLimit(0);
    }, [User, searchTerm, filterStatus, activeCheckbox, approveCheckbox]);
    return (
        <div>
            <Editpopup show={isModalOpen} handleClose={handlePopupToggle} item={singleitem} User={User} />
            <div class='Requested-items-page overflow-auto'>
                <div className='w-full flex justify-between flex-wrap gap-y-1'>
                    <div className='flex lg:w-1/4 w-full md:w-1/3'>
                        <label htmlFor="filter" className='flex items-center'><FaFilter className='text-xs items-center' /> &nbsp;Deal type: &nbsp;</label>

                        <select
                            class="statusdropdown border bg-gray-50 rounded-lg px-3 py-2 flex-grow"
                            id="filter"
                            name="filter"
                            value={filterStatus}
                            onChange={(e) => {
                                setFilterStatus(e.target.value);
                            }}
                        >
                            <option value='Select'>Select</option>
                            <option value='Rating only'>Rating only</option>
                            <option value='Rating & Review'>Rating and Review</option>
                            <option value='High Discount'>High Discount</option>
                            <option value='Only order'>Only order</option>
                            <option value='Empty box'>Empty box</option>
                        </select>
                    </div>
                    <div className='lg:w-1/4 w-full md:w-1/3'>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search..."
                            className="search-input border bg-gray-50 rounded-lg px-3 py-2 w-full"
                        />
                    </div>
                    <div className='lg:flex lg:justify-end md:flex md:justify-end sm:flex sm:justify-end flex justify-end'>
                        <button
                            class={`bg-yellow-300 text-2xl p-3 rounded-lg shadow-md`}
                            disabled={limit == 0}
                            onClick={PrvItem} >
                            <span class=""><RiArrowLeftSLine /></span>
                        </button>
                        <div className="bg-black bg-opacity-50 rounded-md mx-2 text-white text-center flex items-center px-4">Page: {limit / 10}</div>
                        <button
                            class={`bg-yellow-300 text-2xl p-3 rounded-lg shadow-md`}
                            disabled={selecteditems.length < 10}
                            onClick={nextItem}>
                            <span class=""><RiArrowRightSLine /></span>
                        </button>
                    </div>
                </div>
                <table class='Requesteditems-table'>
                    <thead className='tableHeader' style={{ top: '63px' }}>
                        <tr>
                            <th>Date</th>
                            <th style={{ display: user?.Type == 'Admin' ? '' : 'none' }}>Seller</th>
                            <th>ASIN</th>
                            <th className=''>Deal Title</th>
                            <th>Total Deals</th>
                            <th>Deals claimed</th>
                            <th>Edit</th>
                            <th className='flex items-center'>
                                <input type="checkbox" className='w-5 h-5 mr-1'
                                    onChange={() => setActiveCheckbox(!activeCheckbox)}
                                />
                                Active Status
                            </th>
                            {User[0]?.Type == "Admin" ? <th className=''>
                                <div className='flex items-center'>
                                    <input type="checkbox" className='w-5 h-5 mr-1 '
                                        onChange={() => setApproveCheckbox(!approveCheckbox)}
                                    />
                                    Admin Approval
                                </div>
                            </th> : null}
                            <th>Delete</th>

                        </tr>
                    </thead>
                    <tbody>
                        {selecteditems.map((item, index) => (
                            <tr key={item.id} class='RetdItem'>
                                <td>{item['Created Date']}</td>
                                <td style={{ display: user?.Type == 'Admin' ? '' : 'none' }} >
                                    <strong>{item.SellerMail}</strong>
                                </td>
                                <td>
                                    <a href={item.WebLink} target="_blank"><strong>{item.ItemCode}</strong></a>
                                </td>
                                <td >
                                    <Link to={"/RequestedItems/" + item.ItemCode+"/"+limit}>{item.Name.length > 32 ? item.Name.slice(0, 32) : item.Name}</Link>
                                </td>
                                <td>{item.NoDeals}</td>
                                <td>{item.DealsClamed}</td>
                                <td className="cursor-pointer" onClick={() => { handlePopupToggle(); setSingleitem(item) }}>&#9998;</td>
                                <td>
                                    <div className={`toggle ${item.Status == "Activate" ? 'active' : ''}`} onClick={() => { handleStatusChange(item.Status, item.id, 'Status') }} >
                                        <div className={item.Status === "Activate" ? 'on-text' : 'off-text'}>
                                            {item.Status === "Activate" ? 'on' : 'off'}
                                        </div>
                                        <div className="inner-circle"></div>
                                    </div>
                                </td>
                                {User[0]?.Type == "Admin" ? <td>
                                    <div className={`toggle ${item.AdminApprove == "Activate" ? 'active' : ''}`} onClick={() => { handleStatusChange(item.AdminApprove, item.id, 'AdminApprove') }} >
                                        <div className={item.AdminApprove === "Activate" ? 'on-text' : 'off-text'}>
                                            {item.AdminApprove === "Activate" ? 'on' : 'off'}
                                        </div>
                                        <div className="inner-circle"></div>
                                    </div>
                                </td> : null}
                                <td className='text-2xl'>
                                    <button
                                        onClick={() => deleteItem(item.ItemCode)}
                                        disabled={item.Status == "Activate" || item.AdminApprove == "Activate"}
                                    >
                                        <RiDeleteBin6Line />
                                    </button>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default DealsAdded
