
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import profile from './../Images/Profile.jpg';
import { FaUserEdit } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineLocalPhone } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { BsCart3 } from "react-icons/bs";
import { MdOutlinePrivacyTip } from "react-icons/md";


const ProfileDetails = ({ User, Pic }) => {
  const [pic, setPic] = useState(Pic)
  const x = User[0];
  const navigate = useNavigate();
  useEffect(() => {
    setPic(pic)
  }, [User, Pic])
  return (
    <>
      <div className="lg:flex max-w-4xl mx-auto justify-center lg:space-x-3 lg:mt-6 px-2 py-2">
        <div className="mt-3 rounded-md  shadow-lg lg:flex-col flex border bg-white p-3  lg:p-10 lg:space-y-3">
        <div className="">
          {pic ? (
            <img
              src={pic}
              alt="Profile"
              className=" w-24 h-24 rounded-md " />
          ) : (
            <img
              src={profile}
              alt="Avatar"
              className="mx-auto w-24 h-24 rounded-md " />
          )}
            <span className="mt-3 font-bold"> {x.FirstName} {x.LastName}</span>
          </div>
          <div className='ml-4 lg:ml-0'>
          <div className='flex flex-col gap-y-2 mb-2'>
            <button onClick={() => { navigate('/SelectedItems'); }}>
              <div className="transition-transform flex transform hover:scale-110 text-black">
                <span className="mt-1"><BsCart3 /></span>
                &nbsp;  My Orders
              </div>
            </button>
            <button onClick={() => { navigate('/privacypolicy'); }}>
              <div className="transition-transform flex transform hover:scale-110 text-black">
                <span className="mt-1"><MdOutlinePrivacyTip /></span>
                &nbsp; Privacy Policy
              </div>
            </button>
          </div>
          <button onClick={() => { navigate('/Raiseticket'); }}>
            <div className="mb-4  px-3 py-1 w-fit text-blue-400 border border-black rounded-md">
              Raise issue
            </div>
          </button>
          </div>
        </div>
        <div className="flex-col">
          <div className="bg-[#ffffffea] border rounded-md shadow-lg mt-3 p-4">
            <h2 className="text-2xl font-semibold">Profile </h2>
            <div className="flex mb-6 lg:justify-center lg:space-x-40 space-x-6 items-center flex-wrap">
              <p className="m-0 whitespace-normal break-words font-serif text-sm">Basic info, for a faster Booking experience</p>
              <div className="flex border rounded-md border-black px-3 cursor-pointer text-blue-400" onClick={() => { navigate('/EditUser'); }}>
                Edit
                {/* <span className="mt-1 text-blue-400"><MdModeEdit /></span> */}
              </div>
            </div>
            <div className="p-2">
              <label className="text-gray-700  text-sm block font-bold space-x-20 ">
                <span className='text-sm'>Name</span> &nbsp;
                <span className="text-gray-800 font-semibold">{x.FirstName} {x.LastName}</span>
              </label>

            </div>
            <hr className="border-t border-gray-300" />
            <div className="p-2">
              <label className="block items-center text-gray-700 text-sm font-bold space-x-24 ">
                <span className='text-sm'>Age</span> &nbsp;
                <span className="text-gray-800 font-semibold">{x.Age}</span>
              </label>
            </div>
            <hr className="border-t border-gray-300" />
            <div className="p-2">
              <label className="block items-center text-gray-700 text-sm font-bold space-x-24">
                <span className='text-sm'>City</span> &nbsp;
                <span className="text-gray-800 font-semibold">{x.City}</span>
              </label>

            </div>
            <hr className="border-t border-gray-300" />
            <div className="p-2">
              <label className="block items-center text-gray-700 text-sm font-bold space-x-20">
                <span className='text-sm'> UPI-ID </span> &nbsp;
                <span className="text-gray-800 font-semibold">{x.UPIID}</span>
              </label>
            </div>
            <hr className="border-t border-gray-300" />
            <div className="p-2">
              <label className="block text-gray-700 items-center text-sm font-bold space-x-20">
                <span className='text-sm'>Gender</span> &nbsp;
                <span className="text-gray-800 font-semibold">{x.Gender}</span>
              </label>
            </div>

          </div>

          <div className="border bg-white rounded-md shadow-lg p-2 mt-2 ">
            <div className>
              <h2 className="text-2xl font-semibold">Login Details</h2>
              <p className="font-serif mt-1 text-sm">Manage Your Email address Mobile number and Password Mobile</p>
            </div>
            <div className="p-2 mt-5">
              <label className="text-gray-700 flex text-sm items-center font-bold space-x-20 ">
                <span className='text-lg '> <HiOutlineMail /></span> &nbsp;
                <span className="text-gray-800 font-semibold"> {x.MailID}</span>
              </label>

            </div>
            <hr className="border-t border-gray-300" />

            <div className="p-2">
              <label className=" text-gray-700 text-sm font-bold mb-2 flex items-center space-x-20">
                <span className='text-lg'><MdOutlineLocalPhone /></span> &nbsp;
                <span className="text-gray-800 font-semibold">  {x.Phone}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default ProfileDetails;