const sendEmail = async (email,sub,message) => {
    try {
      const response = await fetch('https://sssxbvyyprbuuawbpmep.supabase.co/functions/v1/sendmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsImtpZCI6Ilkvcmg2dFFVQlBLV3ZVMzciLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNzE1NjY4ODU4LCJpYXQiOjE3MTU2NjUyNTgsImlzcyI6Imh0dHBzOi8vc3NzeGJ2eXlwcmJ1dWF3YnBtZXAuc3VwYWJhc2UuY28vYXV0aC92MSIsInN1YiI6IjllMmEyZTMyLWNlZTUtNDAxYy1iMzQyLTRjMjU0NjA5YTBjMCIsImVtYWlsIjoiYW1laDEwMzFAZ21haWwuY29tIiwicGhvbmUiOiIiLCJhcHBfbWV0YWRhdGEiOnsicHJvdmlkZXIiOiJlbWFpbCIsInByb3ZpZGVycyI6WyJlbWFpbCJdfSwidXNlcl9tZXRhZGF0YSI6eyJlbWFpbCI6ImFtZWgxMDMxQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwicGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJzdWIiOiI5ZTJhMmUzMi1jZWU1LTQwMWMtYjM0Mi00YzI1NDYwOWEwYzAifSwicm9sZSI6ImF1dGhlbnRpY2F0ZWQiLCJhYWwiOiJhYWwxIiwiYW1yIjpbeyJtZXRob2QiOiJwYXNzd29yZCIsInRpbWVzdGFtcCI6MTcxNTY2NTI1OH1dLCJzZXNzaW9uX2lkIjoiMTEzMzE4ZjktNWY1YS00N2E3LTk0MTItODNjYWIzYzkwODVkIiwiaXNfYW5vbnltb3VzIjpmYWxzZX0.zeypxPjHRBYndsUJZcBRjlY32PFBKeiSI3260LRLVaY`, // Use the stored token
        },
        body: JSON.stringify({
          to: email,
          subject:sub,
          text: message,
        }),
      });

      if (response.ok) {
        console.log('Email sent successfully!');
      } else {
        console.error('Failed to send email:', response.statusText);
        // setStatus('Failed to send email1.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    //   setStatus('Failed to send email.');
    }
  };
export default sendEmail;