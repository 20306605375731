import React, { useState, useEffect, useCallback } from 'react';
import NavBars from './Components/Header/NavBars';
import Products from './Components/Main/Products';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from './Components/Header/Register';
import ProfileDetails from './Components/Header/ProfileDetails';
import SingleItem from './Components/AddDeals/SingleItem';
import MultiItems from './Components/AddDeals/MultiItems';
import SelectedItems from './Components/SelectedItems/SelectedItems';
import RequestedItems from './Components/SelectedItems/RequestedItems';
import UsersData from './Components/AdminControl/UsersData';
import supabaseClient from './supabaseClient';
import DealsAdded from './Components/AdminControl/DealsAdded';
import Raiseticket from './Components/Ticketing/Raiseticket';
import Viewticket from './Components/Ticketing/Viewticket';
import SellerPage from './Components/Main/SellerPage';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import PasswordReset from './Components/Login/PasswordReset';
import { AiOutlineCloseCircle } from "react-icons/ai";

function App() {
    const [user, setUser] = useState([]);
    const [show, setshow] = useState(false)
    const [headerHeight, setHeaderHeight] = useState(0);
    const GUser = JSON.parse(localStorage.getItem('profile'));

    const checkGoogleID = useCallback(async (GID) => {

        try {
            const { data, error } = await supabaseClient
                .from('Users')
                .select('*')
                .eq('MailID', String(GID));

            if (error) {
                console.error('Error checking Google ID:', error.message);
            } else {
                // console.log(data);
                if (data.length > 0) {
                    setUser(data);
                }
            }
        } catch (error) {
            console.error('Error checking Google ID:', error.message);
        }
    }, []);

    useEffect(() => {
        checkGoogleID(GUser?.email);
    }, [localStorage.getItem('profile')]);
    useEffect(() => {
        const navHeight = document.getElementById('navbar').offsetHeight;
        setHeaderHeight(navHeight);
  
    }, []);
    let count = 0;
    useEffect(() => {
        if (count === 0) {
            count = 1;
            supabaseClient.auth.onAuthStateChange(async (event, session) => {
                if (event === 'PASSWORD_RECOVERY') {
                    setshow(true);
                }
            });
        }
    }, []);
    return (
        <div className="container" style={{ maxWidth: '100%' }}>
            <BrowserRouter>
                <NavBars User={user} />
                <div style={{ height: headerHeight, margin: '2px' }}></div>

                {/* {JSON.stringify(GUser)} */}
                <Routes>
                    <Route path="/" element={<Products User={user} />} />
                    <Route path="/ProfileDetails" element={user.length > 0 ? <ProfileDetails User={user} Pic={GUser.picture} /> : <Register User={user[0]} />} />
                    <Route path="/EditUser" element={<Register User={user[0]} />} />
                    <Route path="/SingleItem" element={<SingleItem User={user} />} />
                    <Route path="/MultiItems" element={<MultiItems User={user} />} />
                    <Route path="/SelectedItems" element={<SelectedItems User={user} />} />
                    <Route path="/RequestedItems/:itcode/:lmt" element={<RequestedItems User={user} />} />
                    <Route path="/UsersData" element={<UsersData User={user[0]}/>} />
                    <Route path="/DealsAdded" element={<DealsAdded User={user} />} />
                    <Route path="/Raiseticket" element={<Raiseticket User={user} />} />
                    <Route path="/Viewticket" element={<Viewticket User={user} />} />
                    <Route path="/SellerPage" element={<SellerPage User={user[0]} />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
                </Routes>
                <div className={show ? "modal-login display-block backdrop-blur-lg w-screen lg:left-0 lg:top-16 pt-20 top-0 overflow-hidden" : "modal-login display-none"}>
                    <section className="modal-main-item-login w-[90%] lg:w-[25em] mx-auto mt-4 flex bg-white p-5  flex-row md:flex-col  md:h-90v relative rounded-2xl overflow-hidden ">
                        <div className='h-16  text-2xl absolute right-4 z-10 overflow-hidden' >
                            <button onClick={() => { setshow(false) }}><b><AiOutlineCloseCircle /></b></button></div>
                            <PasswordReset closePop={()=>setshow(false)}/>

                    </section>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
