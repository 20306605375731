import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabaseClient from '../../supabaseClient';
import './EditPopup.css';
import { AiOutlineCloseCircle } from "react-icons/ai";

const Editpopup = ({ handleClose, show, item, User }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    const user = User[0];
    const [formData, setFormData] = useState(item);

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const updateItem = async () => {
        const ItemCode = formData.ItemCode;
        const Name = formData.Name;
        const Price = formData.Price;
        const Color = formData.Color;
        const Discount = formData.Discount;
        const Description = formData.Description;
        const Size = formData.Size;
        const Rating = formData.Rating;
        const WebLink = formData.WebLink;
        const Gender = formData.Gender;
        const Category = formData.Category;
        const Priority = formData.Priority;
        const NoDeals = formData.NoDeals;
        // const SellerName = user.FirstName + ' ' + user.LastName;
        // const SellerMail = user.MailID;
        const DealType = formData.DealType;
        const Percentageofcashabck = formData.Percentageofcashabck;
        const { data, error } = await supabaseClient
            .from('Items')
            .upsert([{
                id: formData.id,
                ItemCode,
                Name,
                Price,
                Color,
                Discount,
                Description,
                Size,
                Rating,
                WebLink,
                Gender,
                Category,
                Priority,
                // SellerName,
                // SellerMail,
                NoDeals,
                DealType,
                Percentageofcashabck
            }], {
                onConflict: ['id'], // assuming 'id' is the primary key column
                returning: 'minimal' // specify what you want to return after the operation
            });

        if (error) {
            console.error('Error updating item:', error.message);
        } else {
            console.log('Item updated successfully:', data);
            alert("Updated Successfully");
            handleClose()
        }
    }


    const handleSubmit1 = async (e) => {
        e.preventDefault();
        const validationErrors = {};
        const { name, value } = e.target;
        let error = '';

        if (!formData.Name || !formData.Name.trim()) {
            validationErrors.Name = 'First name is required';
        }

        if (!formData.ItemCode || !formData.ItemCode.trim()) {
            validationErrors.ItemCode = 'ItemCode is required';
        }

     
            const discountValue = parseInt(formData.Discount);
            if ( discountValue < 0 || discountValue > 100) {
                validationErrors.Discount = 'Discount Range Error';
            }

        if (!formData.Price || !formData.Price) {
            validationErrors.Price = 'Price is required';
        }
        if (!formData.NoDeals || !formData.NoDeals) {
            validationErrors.NoDeals = 'Total Orders is required';
        }
        if (!formData.Percentageofcashabck || !formData.Percentageofcashabck) {
            validationErrors.Percentageofcashabck = 'Refund amount is required';
        }
        if (formData.DealType == 'Select Type') {
            validationErrors.DealType = 'Deal Type is required';
        }
        console.log(validationErrors);

        setErrors(validationErrors);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        if (Object.keys(validationErrors).length === 0) {
            // Perform your form submission logic here
            updateItem();

        }
    };
    useEffect(() => { setFormData(item) }, [item])
    const inputSizecss ='lg:w-44 mg:w-32 w-full'
    return (
        <div>
            <div className={showHideClassName+' backdrop-blur-lg'}>
                <section className="modal-main-edit">
                    <form className="flex flex-col items-center w-full h-full overflow-auto py-4 ">
                        <h1 className='text-center lg:text-xl text-5xl font-bold'>Edit Deals</h1>

                        <div className="flex flex-col w-full overflow-auto">
                            <div className='m-4'>
                                <div className="">
                                    <label className=" text-blue-950 lg:text-sm  text-2xl font-bold lg:-mb-4 mb-2" htmlFor="ItemCode">
                                        ASIN : <span className='font-normal lg:text-sm text-2xl'>{formData.ItemCode}</span>
                                    </label>
                                </div>
                                <div>
                                    <label className=" text-blue-950 lg:text-sm text-2xl font-bold " htmlFor="WebLink">
                                        Product Link : <a href={formData.WebLink} target='blank'><span className='font-normal lg:text-sm text-2xl'>{formData.WebLink?.slice(0,50)}...</span></a>
                                    </label>
                                </div>
                            </div>
                            <div className='flex flex-wrap items-center gap-x-2 gap-y-4 m-4'>
                                {/* <div className='m-2 flex flex-col justify-between w-full sm:w-auto'> */}
                                    <div className={inputSizecss}>
                                        <label className="text-blue-950 lg:text-sm text-2xl font-bold mb-2" htmlFor="Name">
                                            Deal Title
                                        </label>
                                        <input
                                            type="text"
                                            id="Name"
                                            name="Name"
                                            value={formData.Name}
                                            onChange={handleChange}

                                            className={`border ${errors.Name ? 'border-red-500' : 'border-gray-300'
                                                } p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}
                                        />
                                        {errors.Name && <p className="text-red-500 text-xl mt-0 ">{errors.Name}</p>}
                                    </div>

                                    <div className={inputSizecss}>
                                        <label className=" text-blue-950 lg:text-sm text-2xl font-bold mb-2 " htmlFor="Price">
                                            Price
                                        </label>
                                        <input
                                            type="number"
                                            id="Price"
                                            name="Price"
                                            value={formData.Price}
                                            onChange={handleChange}

                                            className={`border ${errors.Price ? 'border-red-500' : 'border-gray-300'
                                                } p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}
                                        />
                                        {errors.Price && <p className="text-red-500 lg:text-sm text-2xl mt-0">{errors.Price}</p>}
                                    </div>

                                    <div className={inputSizecss}>
                                        <label className=" text-blue-950 lg:text-sm text-2xl font-bold mb-2" htmlFor="NoDeals">
                                            Total Orders
                                        </label>
                                        <input
                                            type="number"
                                            id="NoDeals"
                                            name="NoDeals"
                                            min={0}
                                            value={formData.NoDeals}
                                            onChange={handleChange}

                                            className={`border ${errors.NoDeals ? 'border-red-500' : 'border-gray-300'
                                                } p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}
                                        />
                                        {errors.NoDeals && <p className="text-red-500 lg:text-sm text-lg mt-0">{errors.NoDeals}</p>}
                                    </div>
                                    <div className={inputSizecss}>
                                        <label className=" text-blue-950 lg:text-sm text-2xl font-bold mb-2" htmlFor="DealType">
                                            Deal Type
                                        </label>
                                        <select
                                            type="Dropdown"
                                            id="DealType"
                                            name="DealType"
                                            value={formData.DealType}
                                            onChange={handleChange}
                                            className={`border ${errors.DealType ? 'border-red-500' : 'border-gray-300'
                                                } p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}
                                        >
                                            <option value={null}>Select Type</option>
                                            <option value="Rating only">Rating Only</option>
                                            <option value="Rating & review">Rating & review</option>
                                            <option value="High Discount">High Discount</option>
                                            <option value="Only order">Only Order</option>

                                        </select>
                                        {errors.DealType && <p className="text-red-500 lg:text-sm text-lg mt-0">{errors.DealType}</p>}
                                    </div>
                                {/* </div> */}

                                {/* <div className='m-2 flex flex-col justify-between w-full sm:w-auto'> */}
                                    <div className={inputSizecss}>
                                        <label className=" text-blue-950 lg:text-sm text-2xl font-bold mb-2" htmlFor="Discount">
                                            Discount
                                        </label>
                                        <input
                                            type="number"
                                            id="Discount"
                                            name="Discount"
                                            min={0}
                                            max={100}
                                            value={formData.Discount}
                                            onChange={handleChange}

                                            className={`border ${errors.Discount ? 'border-red-500' : 'border-gray-300'
                                                } p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}
                                        />
                                        {errors.Discount && <p className="text-red-500 lg:text-sm text-lg mt-0">{errors.Discount}</p>}
                                    </div>

                                    <div className={inputSizecss}>
                                        <label className=" text-blue-950 lg:text-sm text-2xl font-bold mb-2" htmlFor="Description">
                                            Deal Description
                                        </label>
                                        <input
                                            type="text"
                                            id="Description"
                                            name="Description"
                                            value={formData.Description}
                                            onChange={handleChange}

                                            className={`border ${errors.Description ? 'border-red-500' : 'border-gray-300'
                                                } p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}
                                        />
                                        {errors.Description && <p className="text-red-500 lg:text-sm text-lg mt-0">{errors.Description}</p>}
                                    </div>
                            
                                    <div className={inputSizecss}>
                                        <label className=" text-blue-950 lg:text-sm text-2xl font-bold mb-2" htmlFor="Percentageofcashabck">
                                            Amount to be refunded
                                        </label>
                                        <input
                                            type="number"
                                            id="Percentageofcashabck"
                                            name="Percentageofcashabck"
                                            value={formData.Percentageofcashabck}
                                            min={0}
                                            max={100}
                                            onChange={handleChange}

                                            className={`border ${errors.Percentageofcashabck ? 'border-red-500' : 'border-gray-300'
                                                } p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}
                                        />
                                        {errors.Percentageofcashabck && <p className="text-red-500 lg:text-sm text-lg mt-0">{errors.Percentageofcashabck}</p>}
                                    </div>
                                    
                                {/* </div> */}
                                {/* <div className='m-2 flex flex-col justify-between w-full sm:w-auto'> */}
                                    <div className={inputSizecss}>
                                        <label className=" text-blue-950 lg:text-sm text-2xl font-bold mb-2" htmlFor="Gender">
                                            Gender
                                        </label>
                                        <select
                                            type="Dropdown"
                                            id="Gender"
                                            name="Gender"
                                            value={formData.Gender}
                                            onChange={handleChange}
                                            className={`border ${errors.Gender ? 'border-red-500' : 'border-gray-300'
                                                } p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}
                                        >
                                            <option >Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                        {errors.State && <p className="text-red-500 lg:text-sm text-lg mt-0">{errors.State}</p>}
                                    </div>
                                    <div className={inputSizecss}>
                                        <label className=" text-blue-950 lg:text-sm text-2xl font-bold mb-2" htmlFor="Size">
                                            Size
                                        </label>
                                        <select
                                            type="Dropdown"
                                            id="Size"
                                            name="Size"
                                            value={formData.Size}
                                            onChange={handleChange}
                                            className={`border ${errors.Size ? 'border-red-500' : 'border-gray-300'
                                                } p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}
                                        >
                                            <option value="">Select Size</option>
                                            <option value="XS">XS</option>
                                            <option value="S">S</option>
                                            <option value="M">M</option>
                                            <option value="L">L</option>
                                            <option value="XL">XL</option>
                                            <option value="XXL">XXL</option>
                                        </select>
                                        {errors.Size && <p className="text-red-500 lg:text-sm text-lg mt-0">{errors.Size}</p>}
                                    </div>
                                    <div className={inputSizecss}>
                                        <label className="text-blue-950 lg:text-sm text-2xl font-bold mb-2" htmlFor="Category">
                                            Category
                                        </label>
                                        <select
                                            type="Category"
                                            id="Category"
                                            name="Category"
                                            value={formData.Category}
                                            onChange={handleChange}
                                            className={`border 'border-gray-300'
                                    p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}

                                        >
                                            <option >Select Category</option>
                                            <option value="Fashion">Fashion</option>
                                            <option value="Home, Kitchen & Outdoors">Home, Kitchen & Outdoors</option>
                                            <option value="Electronics, Appliances & Accessories">Electronics, Appliances & Accessories</option>
                                            <option value="Books & Entertainment">Books & Entertainment</option>
                                            <option value="Others">Others</option>

                                        </select>
                                        {errors.Category && <p className="text-red-500 lg:text-sm text-lg mt-0">{errors.Category}</p>}
                                    </div>
                                    {User[0]?.Type == "Admin" ? <div className={inputSizecss}>
                                        <label className="text-blue-950 lg:text-sm text-2xl font-bold mb-2" htmlFor="Priority">
                                            Priority
                                        </label>
                                        <select
                                            type="Priority"
                                            id="Priority"
                                            name="Priority"
                                            value={formData.Priority}
                                            onChange={handleChange}
                                            className={`border 'border-gray-300'
                                    p-2 lg:p-0 w-full rounded-md text-2xl lg:text-sm`}
                                        >
                                            <option >Select Priority</option>
                                            <option value="1">High</option>
                                            <option value="2">Medium</option>
                                            <option value="3">Low</option>
                                        </select>
                                    </div> : null}

                                </div>
                            </div>
                        {/* </div> */}
                        <div className='mt-4'>
                            <button
                                type="submit"
                                className="bg-blue-500 text-white float-right px-4 py-1 rounded-md "
                                onClick={handleSubmit1}
                            >
                                Submit
                            </button>
                        </div>

                    </form>
                    <div className='lg:relative absolute right-10 lg:right-0 lg:text-2xl text-3xl'><button onClick={() => { handleClose() }}><b><AiOutlineCloseCircle /></b></button></div>
                </section>
            </div>
        </div>
    )
}

export default Editpopup;